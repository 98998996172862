import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Switch,
  Alert,
  StyleSheet,
  ScrollView,
  Linking,
  Pressable,
  Platform,
} from "react-native";
import * as Notifications from "expo-notifications";
import { getFirestore, doc, getDoc, updateDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import * as Device from "expo-device";

const db = getFirestore();
const auth = getAuth();

async function registerForPushNotificationsAsync() {
  let token;
  if (Platform.OS === "android") {
    await Notifications.setNotificationChannelAsync("default", {
      name: "default",
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: "#FF231F7C",
    });
  }

  let { status: existingStatus } = await Notifications.getPermissionsAsync();
  if (existingStatus !== "granted") {
    const { status } = await Notifications.requestPermissionsAsync();
    existingStatus = status;
  }

  if (existingStatus !== "granted") {
    Alert.alert("Permission required", "Failed to get push token for notifications!");
    return;
  }

  token = (await Notifications.getExpoPushTokenAsync()).data;
  return token;
}

// Open the app’s notification settings if possible
const openAppNotificationSettings = () => {
  if (Platform.OS === "ios") {
    // This takes the user to the app's Settings page; 
    // Apple doesn't allow direct linking to the Notifications sub-page.
    Linking.openURL("app-settings:").catch(() =>
      Alert.alert("Error", "Unable to open app settings.")
    );
  } else {
    // On many Android versions, this opens directly to the app's notification settings.
    // Some OEMs or older versions may behave differently.
    Linking.openSettings().catch(() =>
      Alert.alert("Error", "Unable to open notification settings.")
    );
  }
};

const NotificationSettings = () => {
  const [isEnabled, setIsEnabled] = useState(false);
  const [pushToken, setPushToken] = useState("");
  const [deviceInfo, setDeviceInfo] = useState({ brand: "", model: "" });
  const [userEmail, setUserEmail] = useState("");
  const userId = auth.currentUser?.uid;

  useEffect(() => {
    fetchUserData();
    checkNotificationPermissions();

    const currentUser = auth.currentUser;
    if (currentUser) {
      setUserEmail(currentUser.email);
    }
  }, []);

  const fetchUserData = async () => {
    if (userId) {
      const userDocRef = doc(db, "users", userId);
      const userDocSnapshot = await getDoc(userDocRef);
      if (userDocSnapshot.exists()) {
        const data = userDocSnapshot.data();
        setPushToken(data.expoPushToken || "No token found");
        setDeviceInfo({
          brand: data.deviceBrand || "Unknown brand",
          model: data.deviceModel || "Unknown model",
        });
      }
    }
  };

  const checkNotificationPermissions = async () => {
    const { status } = await Notifications.getPermissionsAsync();
    setIsEnabled(status === "granted");
  };

  const toggleSwitch = async () => {
    if (!isEnabled) {
      const { status } = await Notifications.requestPermissionsAsync();
      if (status !== "granted") {
        Alert.alert(
          "Permission Required",
          "Please enable notifications in your settings.",
          [
            { text: "Cancel", style: "cancel" },
            { text: "Open Settings", onPress: openAppNotificationSettings },
          ]
        );
        return;
      }
    }
    const newStatus = !isEnabled;
    setIsEnabled(newStatus);
    handleNotificationPreference(newStatus);
  };

  const handleNotificationPreference = async (enabled) => {
    try {
      if (userId) {
        const userDocRef = doc(db, "users", userId);
        await updateDoc(userDocRef, { notification: enabled });
      }
      if (enabled) {
        await Notifications.scheduleNotificationAsync({
          content: {
            title: "Notifications Enabled",
            body: "You will receive notifications.",
          },
          trigger: { seconds: 1 },
        });
      } else {
        await Notifications.cancelAllScheduledNotificationsAsync();
      }
    } catch (error) {
      console.error("Error updating notification preference:", error);
    }
  };

  const handleRefreshDeviceInfo = () => {
    Alert.alert(
      "Device Info",
      `Brand: ${Device.brand}\nModel: ${Device.modelName}`
    );
  };

  const handleRefreshPushToken = async () => {
    try {
      const token = await registerForPushNotificationsAsync();
      if (token && userId) {
        setPushToken(token);
        const userDocRef = doc(db, "users", userId);
        await updateDoc(userDocRef, {
          expoPushToken: token,
          deviceBrand: Device.brand,
          deviceModel: Device.modelName,
        });
        setDeviceInfo({
          brand: Device.brand,
          model: Device.modelName,
        });
        Alert.alert("Success", "Push token, brand, and model updated!");
      }
    } catch (error) {
      console.error("Error refreshing push token:", error);
      Alert.alert("Error", "Failed to refresh push token.");
    }
  };

  // Pressing this button takes the user directly to the app's notification settings (best effort).
  const testNotification = async () => {
    openAppNotificationSettings();
  };

  return (
    <ScrollView style={styles.container}>
      <Text style={styles.header}>Notification Settings</Text>
      <Text style={styles.description}>
        Stay updated with the latest game results and get reminders for upcoming
        matches.      
      </Text>
       <Text style={styles.description}>
        Enable notifications to never miss out!
      </Text>

      <View style={styles.switchContainer}>
        <Text style={styles.label}>Enable Notifications</Text>
        <Switch
          trackColor={{ false: "#B0B0B0", true: "#34D399" }}
          thumbColor={isEnabled ? "#FFFFFF" : "#f4f3f4"}
          ios_backgroundColor="#3e3e3e"
          onValueChange={toggleSwitch}
          value={isEnabled}
        />
      </View>

      <View style={styles.infoContainer}>
        <Text style={styles.label}>
          Device Brand: <Text style={styles.boldText}>{deviceInfo.brand}</Text>
        </Text>
        <Text style={styles.label}>
          Device Model: <Text style={styles.boldText}>{deviceInfo.model}</Text>
        </Text>
        {/* {userEmail === "dondanni@hotmail.com" && ( */}
          <Text style={styles.label}>
            Expo Push Token:{" "}
            <Text style={styles.boldText}>
              {pushToken || "Can't fetch token..."}
            </Text>
          </Text>
        {/* )}		 					
					 */}
      </View>

      <Pressable
        onPress={handleRefreshDeviceInfo}
        style={({ pressed }) => [
          styles.refreshButton,
          { opacity: pressed ? 0.7 : 1 },
        ]}
      >
        <Text style={styles.refreshText}>Device Info</Text>
      </Pressable>

      <Pressable
        onPress={handleRefreshPushToken}
        style={({ pressed }) => [
          styles.refreshButton,
          { opacity: pressed ? 0.7 : 1 },
        ]}
      >
        <Text style={styles.refreshText}>Update Device Info</Text>
      </Pressable>

      <Pressable
        onPress={testNotification}
        style={({ pressed }) => [
          styles.testNotificationButton,
          { opacity: pressed ? 0.7 : 1 },
        ]}
      >
        <Text style={styles.refreshText}>Change Notification in Settings</Text>
      </Pressable>
    </ScrollView>
  );
};

export default NotificationSettings;

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20,
    paddingTop: 30,
    backgroundColor: "#F9FAFB",
    flex: 1,
  },
  header: {
    fontSize: 24,
    fontWeight: "bold",
    color: "#1F2937",
    marginBottom: 12,
    textAlign: "center",
  },
  description: {
    fontSize: 16,
    color: "#4B5563",
    textAlign: "center",
    marginBottom: 20,
    lineHeight: 22,
  },
  switchContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: 15,
    paddingHorizontal: 25,
    backgroundColor: "#FFFFFF",
    borderRadius: 10,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.1,
    shadowRadius: 3,
    elevation: 3,
    marginBottom: 25,
  },
  infoContainer: {
    backgroundColor: "#FFFFFF",
    borderRadius: 10,
    padding: 20,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.1,
    shadowRadius: 3,
    elevation: 3,
  },
  label: {
    fontSize: 16,
    color: "#1F2937",
    marginBottom: 10,
  },
  boldText: {
    fontWeight: "bold",
    color: "#111827",
  },
  refreshButton: {
    backgroundColor: "#007bff",
    padding: 14,
    borderRadius: 8,
    marginTop: 20,
    alignItems: "center",
  },
  refreshText: {
    color: "#FFFFFF",
    fontSize: 16,
    fontWeight: "bold",
  },
  testNotificationButton: {
    backgroundColor: "#D97706",
    padding: 14,
    borderRadius: 8,
    marginTop: 20,
    alignItems: "center",
  },
});
