import React, { useState, useEffect, useMemo } from "react";
import { View, Text, Pressable, StyleSheet } from "react-native";
import { database } from "../componenets/firebase";
import { FontAwesome } from "@expo/vector-icons";
import {
  setDoc,
  doc,
  onSnapshot,
  collection,
  query,
  where,
  Timestamp,
  getDoc,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import moment from "moment";  // Import moment

export default function RadioButton({
  
  data,
  onSelect,
  mainGame,
  TheGameId,
  playerId,
  name,
  email,
  GameDate,
  GameIndex,
  checkAndSendNotification,
  playerTokenStatus, // Pass in the player’s token status as a prop
  //currentUserName 
}) {
  const [userOption, setUserOption] = useState(3);
  const [sides, setSides] = useState([]);
  const currentUserEmail = getAuth().currentUser?.email; // Get current user's email
  const [currentUserName, setCurrentUserName] = useState("Unknown");


  useEffect(() => {
    const fetchCurrentUserName = async () => {
      const auth = getAuth();
      const currentUser = auth.currentUser;

      if (currentUser) {
        const userDocRef = doc(database, 'users', currentUser.uid);
        const userSnapshot = await getDoc(userDocRef);

        if (userSnapshot.exists()) {
          const userData = userSnapshot.data();
          setCurrentUserName(userData.name || 'Unknown');
        }
      }
    };

    fetchCurrentUserName();
  }, []);

  

  const selectHandler = (value) => {
    onSelect(value);
    setUserOption(value);
  };

  const SaveRadio = async (item) => {
    const docRef = doc(
      database,
      "MainGame",
      mainGame,
      "TheGame",
      TheGameId,
      "Players",
      playerId
    );
    setDoc(
      docRef,
      {
        Name: name,
        value: item.value,
        ButtonId: item.id,
        id: playerId,
        userId: playerId,
        email: email,
        gameId: TheGameId,
        mainGameId: mainGame,
        LastChanged: getAuth().currentUser.email,
        lastChangedTimestamp: Timestamp.now(),
        gamedate: GameDate,
        date: GameDate,
        score: null,
        scoreAway: null,
        scoreHome: null,
        gameIndex: GameIndex,
        LastChanged: currentUserName,
        


      },
      { merge: true }
    );
  };

  useEffect(() => {
    const colRef = collection(
      database,
      "MainGame",
      mainGame,
      "TheGame",
      TheGameId,
      "Players"
    );
    const q = query(colRef, where("userId", "==", playerId));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      if (!querySnapshot.empty) {
        querySnapshot.docs.forEach((doc) => {
          setSides([{ ...doc.data(), id: doc.id }]);
        });
      } else {
        setSides([{ ButtonId: 3 }]); // Default to "Waiting"
      }
    });

    return () => {
      unsubscribe();
    };
  }, [mainGame, TheGameId, playerId]);

  const radioButtons = useMemo(() => {
    return data.map((item, index) => (
      <Pressable
        key={index}
        style={[
          styles.radioImage,
          sides[0]?.ButtonId === item.id && { backgroundColor: item.radiobg },
        ]}
        onPress={() => {
          selectHandler(item.id), SaveRadio(item);
        }}
      >
        <FontAwesome
          name={sides[0]?.ButtonId === item.PlayerId ? item.icon : "circle-o"}
          size={20}
          color={sides[0]?.ButtonId === item.id ? item.bg : "#000"}
        />
        <Text style={styles.radioText}> {item.value} </Text>
      </Pressable>
    ));
  }, [data, sides]);

  return (
  <View style={styles.container}>
    <View style={styles.radioButtonsContainer}>
      {radioButtons}
      <View style={styles.infoContainer}>
        <View style={styles.changedByContainer}>
          
          <Text style={styles.changedByEmail}>
            {sides[0]?.LastChanged ? `Changed by: ${sides[0].LastChanged}` : ""}
          </Text>
          
          {/* <Text style={styles.changedByEmail}>
              {sides[0]?.LastChanged ? `Changed by_test: ${currentUserName}` : ""}
            </Text>

            <Text style={styles.changedByEmail}>
            {sides[0]?.LastChanged ? `Changed by: ${sides[0].currentUserName}` : ""}
          </Text> */}

            



          {sides[0]?.lastChangedTimestamp && (
            <Text style={styles.timestampText}>
              {moment(sides[0].lastChangedTimestamp.toDate()).format('D MMM YYYY, h:mm A')}
            </Text>
          )}
        </View>

        {/* Conditionally render "Send Reminder" button or "No App" aligned in the same container */}
        {(currentUserEmail === "dondanni@hotmail.com" || currentUserEmail === "thordisosp@gmail.com") && sides[0]?.ButtonId === 3 && (
          <View style={styles.reminderContainer}>
            {playerTokenStatus ? (
              <Pressable
                style={({ pressed }) => [
                  styles.button,
                  pressed ? styles.pressedButton : styles.defaultButton,
                ]}
                onPress={checkAndSendNotification}
              >
                <Text style={styles.buttonText}>Send Reminder</Text>
              </Pressable>
            ) : (
              <Text style={styles.noAppText}>No App</Text>
            )}
          </View>
        )}
      </View>
    </View>
  </View>
);

}



const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  radioButtonsContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  radioImage: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: 80,
    height: 30,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: "#1E0FCA",
    marginHorizontal: 2,
    padding: 4,
  },
  radioText: {
    fontSize: 10,
    fontWeight: "500",
  },
  infoContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginLeft: 10,
  },
  changedByContainer: {
    marginRight: 10,
  },
  changedByEmail: {
    fontSize: 10,
    fontWeight: "500",
    color: "#1E0FCA",
  },
  timestampText: {
    fontSize: 10,
    fontWeight: "500",
    color: "#666",
    marginTop: 2,
  },
 
  buttonText: {
    color: "#FFFFFF",
    fontSize: 10,
  },
  pressedButton: {
    backgroundColor: "#6c757d",
  },
  buttonContent: {
    flexDirection: "row",
    alignItems: "center",
  },
  
  reminderContainer: {
  minWidth: 100,
  alignItems: "center",
  justifyContent: "center",
},
noAppText: {
  fontSize: 10,
  color: "#666",
  paddingVertical: 2,
  paddingHorizontal: 6,
  backgroundColor: "#f0f0f0",
  borderRadius: 4,
  shadowColor: "#000",
  shadowOffset: { width: 0, height: 1 },
  shadowOpacity: 0.2,
  shadowRadius: 1,
  elevation: 1, // For Android shadow
  textAlign: "center",
  minWidth: 80, // Ensures consistent width for alignment
},
button: {
  paddingVertical: 5,
  paddingHorizontal: 10,
  borderRadius: 5,
  backgroundColor: "#007bff",
  minWidth: 80, // Matches width with noAppText
  alignItems: "center",
  justifyContent: "center",
},
buttonText: {
  color: "#FFFFFF",
  fontSize: 10,
  textAlign: "center",
},

changedByContainer: {
  marginRight: 10,
  minWidth: 150, // Fixed width to align consistently with reminderContainer
  justifyContent: "center",
},
reminderContainer: {
  minWidth: 100,
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "row", // Ensures both No App and Send Reminder align
},
noAppText: {
  fontSize: 10,
  color: "#666",
  paddingVertical: 5,
  paddingHorizontal: 10,
  backgroundColor: "#f0f0f0",
  borderRadius: 4,
  shadowColor: "#000",
  shadowOffset: { width: 0, height: 1 },
  shadowOpacity: 0.2,
  shadowRadius: 1,
  elevation: 1,
  textAlign: "center",
  minWidth: 80, // Consistent width with button
},
button: {
  paddingVertical: 5,
  paddingHorizontal: 10,
  borderRadius: 5,
  backgroundColor: "#007bff",
  minWidth: 80, // Matches width with noAppText
  alignItems: "center",
  justifyContent: "center",
},
buttonText: {
  color: "#FFFFFF",
  fontSize: 10,
  textAlign: "center",
},



});
