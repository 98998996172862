 
import React, { useEffect, useRef } from 'react';
import { Button } from 'react-native';

import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import HomeGuest from '../screens/HomeGuest';
import SignInScreen from '../screens/Auth/SignInScreen';
import DeleteAccount from '../screens/DeleteAccount';
import SignUpScreen from '../screens/Auth/SignUpScreen';
import ForgotPasswordScreen from '../screens/Auth/ForgotPasswordScreen';
import About from '../screens/About';

const Stack = createStackNavigator();


const linking = {
  prefixes: ['http://localhost:19006', 'https://teamplay.cool'],
  config: {
    screens: {
      Home: 'home',
      SignIn: 'sign-in',  // Clear and specific path
      SignUp: 'sign-up',  // Clear and specific path
      DeleteAccount: 'DeleteAccount',  // Make sure the URL path matches case sensitivity
      ForgotPassword: 'forgot-password',  // Clear and specific path
      About: 'About',  // Clear and specific path
    },
  },
};

const AuthStack = () => (
  <Stack.Navigator>
    <Stack.Screen name="Home" component={HomeGuest} options={{ headerShown: false }} />
    <Stack.Screen name="Sign In" component={SignInScreen} options={{ title: 'Sign In' }} />
    <Stack.Screen name="DeleteAccount" component={DeleteAccount} options={{ headerShown: false }} />
    <Stack.Screen name="Sign Up" component={SignUpScreen} />
    <Stack.Screen name="ForgotPassword" component={ForgotPasswordScreen} options={{ title: 'Forgot Password' }} />
    <Stack.Screen name="About" component={About} options={{ title: 'About' }} />
  </Stack.Navigator>
);

const TabNavigator2 = () => {
  const navigationRef = useRef();

   
  
  return (
    <NavigationContainer linking={linking} >
    
      <AuthStack />
    </NavigationContainer>
  );
};

export default TabNavigator2;