// Make Game.

import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  SafeAreaView,
  TextInput,
  StyleSheet,
  TouchableOpacity,
  Modal,
  Button,
} from "react-native";
import {
  collection,
  Timestamp,
  getDocs,
  doc,
  setDoc,
  query,
  where,
  addDoc,
  updateDoc,
  Pressable,
} from "firebase/firestore";
import { Ionicons } from "@expo/vector-icons";
import { database } from "../componenets/firebase";
import { useAuthentication } from "../hook/useAuthentication";
import GameModeSelector from "../componenets/GameModeSelector";

import { getAuth, updateProfile } from "firebase/auth";
import moment from "moment";

function CreateQuizScreen({ navigation }) {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const { user } = useAuthentication(); // This is the user object
  const [gameMode, setGameMode] = useState("team");  // Default to "Team"
  const [modalVisible, setModalVisible] = useState(false);
  const auth = getAuth();
  const userT = auth.currentUser;
  const [loading, setLoading] = useState(false); // Add this state to handle loading
  const [userData, setUserData] = useState(null); // Store fetched user data



  useEffect(() => {
    const fetchUserData = async () => {
        setLoading(true); // Start loading
        const currentUser = auth.currentUser;
        if (currentUser) {
            try {
                const usersRef = collection(database, 'users');
                const q = query(usersRef, where('userId', '==', currentUser.uid));
                const querySnapshot = await getDocs(q);

                if (!querySnapshot.empty) {
                    const userData = querySnapshot.docs[0].data();
                    setUserData(userData); // Set the userData state

                    // Log the fetched name
                    console.log("User's name:", userData.name);
                } else {
                    console.log('No such user!');
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            } finally {
                setLoading(false); // End loading
            }
        } else {
            setLoading(false); // End loading if no user is signed in
        }
    };

    fetchUserData();
}, [auth]);




  useEffect(() => {
    if (userT && !userT.displayName) {
      // Assuming you want to update the display name if it's not present
      updateProfile(userT, {
        displayName: "Your New Display Name", // replace with desired name
      })
        .then(() => {
          console.log("Updated user:", userT);
        })
        .catch((error) => {
          console.error("Error updating user profile:", error);
        });
    }
  }, [userT]);

  const generateRoomId = () => {
    let roomId = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < 8; i++) {
      roomId += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return roomId;
  };

  async function roomIdExists(roomId) {
    const roomCollectionRef = collection(database, "MainGame");
    const roomQuery = query(roomCollectionRef, where("roomId", "==", roomId));
    const querySnapshot = await getDocs(roomQuery);

    return !querySnapshot.empty;
  }

  //new code
  const createAndSaveGame = async (gameDate) => {
    try {
      // Check necessary parameters
      if (!gameMode) {
        alert("Please select a game mode before saving.");
        return;
      }

      // Check if a game name is provided
      if (!title) {
        alert("Please enter a game name before saving.");
        return;
      }

      // Generate Room ID
      let generatedRoomId;
      do {
        generatedRoomId = generateRoomId();
      } while (await roomIdExists(generatedRoomId));

      // Create game document in 'MainGame' collection
      const collectionRef = collection(database, "MainGame");
      const newDocRef = doc(collectionRef);
      const gameData = {
        name: title,
        description: description,
        MainGameId: newDocRef.id,
        roomId: generatedRoomId,
        created: Timestamp.now(),
        mode: gameMode,

        // Points settings fields
        winPoints: 1,
        losePoints: 0,
        drawPoints: 0,
        attendancePoints: 0,
        absentPoints: 0,
        TeenGoalPoints: 0,
        pointsEnabled: true,

        memberOfGame: [
          {
            email: user.email,
            name: userData?.name || user.email.split("@")[0], // Use fetched userData name
            uid: user.uid,
            isAdmin: true,
            isOwner: true,
            role: "verified",
          },
        ],
      };
      await setDoc(newDocRef, gameData);
      // Save game date in 'TheGame' subcollection
      const theGameCollectionRef = collection(
        database,
        "MainGame",
        newDocRef.id,
        "TheGame"
      );
      const formattedDate = moment(gameDate).format("YYYY-MM-DD");

      // Check if a game with the same date already exists and determine the new index
      let maxIndex = 0;
      let isSameDay = false;
      const querySnapshot = await getDocs(theGameCollectionRef);
      querySnapshot.forEach((doc) => {
        const gameData = doc.data();
        if (moment(gameData.date).isSame(formattedDate, "day")) {
          isSameDay = true;
          maxIndex = Math.max(maxIndex, gameData.index || 0); // Assuming 'index' field exists in gameData
        }
      });
      const newGameIndex = isSameDay ? maxIndex + 1 : 1;

      // Add the game date document with the calculated index
      const newGameDateDocRef = await addDoc(theGameCollectionRef, {
        gameTitle: title, // Assuming you want to use the game title here
        date: formattedDate,
        created: Timestamp.now(),
        index: newGameIndex, // Include the new index
      });

      // console.log("Results written with ID: ", newGameDateDocRef.id);
      await updateDoc(newGameDateDocRef, {
        MainGameId: newDocRef.id,
      });

      // Navigation or other post-creation logic
      navigation.navigate("Game Date", {
        MainGameId: newDocRef.id,
        gameName: title,
        roomId: generatedRoomId,
        gameMode: gameMode,
      });

      return newDocRef.id; // Optionally return the new game ID
    } catch (error) {
      console.error("Error in game creation:", error);
    }
  };

  const renderDescriptionModal = () => {
    return (
      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(!modalVisible);
        }}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <Text style={styles.modalTitle}>Game Descriptions</Text>

            <View style={styles.descriptionContainer}>
              <Text style={styles.modeTitle}>Individual: (comming soon) </Text>
              <Text style={styles.modeDescription}>
                In this game, each player competes on their own, striving to
                achieve the highest score or to complete a set challenge. No
                teams are involved, so it's every player for themselves!
              </Text>

              <Text style={styles.modeTitle}>Team:</Text>
              <Text style={styles.modeDescription}>
                Players will be organized into teams. The app provides an
                attendance tracker to monitor participation in each game. The
                leaderboard will track scores and points for each game, though
                its use for points is optional.
              </Text>

              <Text style={styles.modeTitle}>BeerCounter: (comming soon) </Text>
              <Text style={styles.modeDescription}>
                This mode seems to be a fun, relaxed game where players (or
                teams) might compete based on the number of beers they drink. It
                could be about keeping track of consumption for fun competitive
                purposes. Note: Always remember to drink responsibly.
              </Text>

              <Text style={styles.modeTitle}>KnockOut: (comming soon) </Text>
              <Text style={styles.modeDescription}>
                In this mode, players compete in rounds, and in each round, one
                or more players are "Knocked out" or eliminated based on
                performance until only the winner or winners remain. It's a
                high-stakes, competitive mode where every round matters.
              </Text>
            </View>

            {/* <Button title="Close" onPress={() => setModalVisible(false)} /> */}

            <View style={{ padding: 10 }}>
              {/* <Button title="Close" onPress={() => setModalVisible(false)} /> */}

              <TouchableOpacity
                style={styles.button}
                onPress={() => setModalVisible(false)}
              >
                <Text style={styles.buttonText}>Close</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
    );
  };

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: "white", padding: 20 }}>
      {renderDescriptionModal()}

      <TextInput
        style={styles.textInput}
        placeholder="Game Name"
        onChangeText={setTitle}
        value={title}
        autoFocus={true}
        selectTextOnFocus={true}
      />
      {/* <TextInput
        style={styles.textInput}
        placeholder="Description - (optional)"
        onChangeText={setDescription}
        value={description}
      /> */}

      <View style={styles.topBar}>
        <TouchableOpacity onPress={() => setModalVisible(true)}>
          <Ionicons
            name="information-circle-outline"
            size={32}
            color="#007BFF"
          />
        </TouchableOpacity>
      </View>

      <GameModeSelector onModeSelect={setGameMode} />

      {/* createGame */}
      <TouchableOpacity style={styles.button} onPress={createAndSaveGame}>
        <Text style={styles.buttonText}>Save Game</Text>
      </TouchableOpacity>
    </SafeAreaView>
  );
}

export default CreateQuizScreen;

const styles = StyleSheet.create({
  // container: {
  //   flex: 1,
  //   justifyContent: "center",
  //   alignItems: "center",
  // },
  textInput: {
    textAlign: "center",
    padding: 10,
    borderColor: "blue",
    borderWidth: 1,
    width: "100%",
    borderRadius: 13,
    marginTop: 10,
    marginBottom: 10,
  },

  button: {
    backgroundColor: "#007BFF",
    borderRadius: 15,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 25,
    paddingVertical: 15,
    paddingHorizontal: 25,
  },
  buttonText: {
    color: "white",
    fontSize: 18,
  },

  // checkboxContainer: {
  //   paddingTop: 10,
  //   flexDirection: "row",
  //   alignItems: "center",
  //   justifyContent: "center",
  // },

  // modal styles
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  modalTitle: {
    marginBottom: 15,
    textAlign: "center",
    fontSize: 18,
    fontWeight: "bold",
  },
  // descriptionButtonContainer: {
  //   margin: 10,
  // },

  topBar: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingRight: 10,
    marginBottom: 10,
  },

  descriptionContainer: {
    marginVertical: 10,
  },
  modeTitle: {
    fontWeight: "bold",
    fontSize: 16,
    marginTop: 10,
  },
  modeDescription: {
    fontSize: 14,
    marginVertical: 5,
    marginLeft: 10,
  },
});
