import React from "react";
import {
  View,
  Text,
  StyleSheet,
  Image,
  Linking,
  SafeAreaView,
  ScrollView,
  TouchableOpacity,
} from "react-native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
//import Ionicons from "react-native-vector-icons/Ionicons";
import { MaterialIcons } from "@expo/vector-icons";

//import { TouchableOpacity } from "react-native-gesture-handler";


import background from "../assets/background.jpg";
import Constants from 'expo-constants';  // Import Constants from Expo



// import {
//   scheduleSpecificNotification
//  // cancelAllScheduledNotifications,
// } from '../navigation/Notification'; // Added scheduleRepeatingNotification


const AboutStack = createNativeStackNavigator();


const About = () => (
  <AboutStack.Navigator
    screenOptions={{
      headerShown: true,
    }}
  >
    <AboutStack.Screen
      name="AboutScreen"
      component={AboutScreen}
      options={({ navigation }) => ({
        headerTitle: () => (
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <TouchableOpacity
              onPress={() => navigation.goBack()}
              style={{ marginRight: 10 }}
            >
              {/* <Ionicons name="arrow-back" size={24} color="white" /> */}
              <MaterialIcons name="arrow-back" size={24} color="white" />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => navigation.goBack()}>
              <Text style={{ color: "white", fontWeight: "bold", fontSize: 18 }}>
                Go back
              </Text>
            </TouchableOpacity>
          </View>
        ),
        headerStyle: {
          backgroundColor: "#4361ee",
        },
        headerTintColor: "white",
        headerTitleStyle: {
          fontWeight: "bold",
          color: "white",
        },
      })}
    />
  </AboutStack.Navigator>
);

const AboutScreen = () => {
  const appVersion = Constants.expoConfig?.version || "Unknown";

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView style={styles.scrollView}>
        <View style={styles.container}>
          <View style={styles.logoContainer}>
            <Image source={background} style={styles.logo} />
          </View>

          <View style={styles.textContainer}>
            <Text style={styles.title}>About TeamPlay Scorecards!</Text>
            <Text style={styles.description}>
              TeamPlay Scorecards is here to help players track their game
              scores easily.
            </Text>
            <Text style={styles.description}>
              Our goal is to change how players keep score and improve their
              game. {"\n\n"}
            </Text>
            {/* <Text style={styles.description}>
We love making things better for our users, so we keep adding new features to our app. {'\n\n'}
</Text> */}
            <Text style={{ ...styles.description, fontSize: 18 }}>
              Join us and experience the best way to keep track of your scores!
            </Text>
          </View>

        
          {/* <View style={styles.buttonContainer}>
            <TouchableOpacity
              onPress={scheduleRepeatingNotification}
              style={styles.button}
            >
              <Text style={styles.buttonText}>Start Notifications</Text>
            </TouchableOpacity>

            <TouchableOpacity
              onPress={cancelAllScheduledNotifications}
              style={styles.button}
            >
              <Text style={styles.buttonText}>Cancel Notifications</Text>
            </TouchableOpacity>
          </View> */}



          <View style={styles.contactContainer}>
            <Text style={styles.contactTitle}>Contact Us!</Text>
            <Text style={styles.contactText}>
              Email:{" "}
              <Text
                style={styles.link}
                onPress={() =>
                  Linking.openURL("mailto:teamplay.cool@gmail.com")
                }
              >
                teamplay.cool@gmail.com
              </Text>
            </Text>
      
            
            <Text style={styles.contactText}>
              Join us on Facebook:{" "}
              <Text
                style={styles.link}
                onPress={() =>
                  Linking.openURL(
                    "https://www.facebook.com/profile.php?id=100092504301277"
                  )
                }
              >
                Facebook
              </Text>
            </Text>

            <Text style={styles.contactText}>
              Videos and tutorials: {" "}
              <Text
                style={styles.link}
                onPress={() =>
                  Linking.openURL(
                    //"https://www.youtube.com/watch?v=X3pmh7QJFeA&feature=youtu.be"
                    "https://www.youtube.com/watch?v=G2rrj7zkoAQ"
                    
                  )
                }
              >
                Youtube
              </Text>
            </Text>

            <Text style={styles.contactText}>
              Website:{" "}
              <Text
                style={styles.link}
                onPress={() => Linking.openURL("https://teamplay.cool/")}
              >
                teamplay.cool
              </Text>
              

              {/* <Text> ( all platform ) </Text> */}
            </Text>
            <Text style={styles.contactText}> Created: 15 may 2023 </Text>
             <Text style={styles.contactText}>App Version: {appVersion}</Text>

            {/* <Text style={styles.contactText}> Andriod: - ready 1.júlí 2024 </Text>
            <Text style={styles.contactText}> Apple store: In Testflight - coming soon </Text> */}
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#f5f5f5",
    padding: 16,
  },
  logoContainer: {
    alignItems: "center",
    justifyContent: "center",
  },
  logo: {
    width: 300,
    height: 300,
    resizeMode: "contain",
  },
  textContainer: {
    marginTop: 16,
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: 8,
  },
  description: {
    fontSize: 16,
    textAlign: "center",
  },

  contactTitle: {
    marginTop: 40,
    fontSize: 18,
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: 8,
  },
  contactText: {
    fontSize: 16,
    textAlign: "center",
    marginBottom: 8,
  },
  link: {
    color: "blue",
    textDecorationLine: "underline",
  },

  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginTop: 20,
  },
  button: {
    backgroundColor: '#4361ee',
    padding: 10,
    borderRadius: 5,
  },
  buttonText: {
    color: 'white',
    textAlign: 'center',
  },


});

export default About;
