import React, { useContext, useEffect, useState, useRef } from "react";
import * as Notifications from "expo-notifications";
import "react-native-gesture-handler";
import { useAuthentication } from "../hook/useAuthentication";
import Navigation from "./Navigation"; // Main app navigation
import TabNavigator2 from "./authStack"; // Authentication stack
import { AppContext } from "../screens/AppContext"; // Import AppContext

import { getExpoPushToken, scheduleSpecificNotification } from "./Notification";
//import { logEvent, getAnalytics } from 'firebase/analytics';
import { Platform } from "react-native";

export default function RootNavigation() {
  const { user } = useAuthentication(); // Get the authenticated user (if any)
  const { isDemo } = useContext(AppContext); // Access demo mode state
  const [expoPushToken, setExpoPushToken] = useState("");
  const routeNameRef = useRef();
  const navigationRef = useRef();

  console.log("isDemo:", isDemo); // Debug statement for demo mode
  console.log("user:", user); // Debug statement for authenticated user

  useEffect(() => {
    // Listener for handling notifications when the app is in the foreground
    const subscription = Notifications.addNotificationResponseReceivedListener(
      (response) => {
        console.log("Notification response received:", response);
        // Additional handling if needed, like navigation
      }
    );

    // Clean up the listener on unmount
    return () => subscription.remove();
  }, []);

  // Logic to determine navigation flow
  // If `user` exists or `isDemo` is true, load the main app navigation
  return user || isDemo ? (
    <Navigation
      navigationRef={navigationRef}
      routeNameRef={routeNameRef}
    />
  ) : (
    // Otherwise, load the authentication stack
    <TabNavigator2 />
  );
}
