// MyGames.js  
 
import React, { useContext  , useState, useEffect, useCallback, useMemo } from "react";
import {
  View,
  Text,
  SafeAreaView,
  FlatList,
  StyleSheet,
  TouchableOpacity,
  TextInput,
  Platform,
  Image,
  ScrollView,
  Dimensions,
  Pressable,
  Linking,
  Button,
  ActivityIndicator 
} from "react-native";

import { AppContext } from "../screens/AppContext";


import { useUser } from "../navigation/UserContext"; // Adjust the path as needed

//import { Button } from "react-native-elements";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
const auth = getAuth();

//import MaterialIcons from "react-native-vector-icons/MaterialIcons";

import { MaterialIcons } from "@expo/vector-icons";

import { database } from "../componenets/firebase";
import {
  collection,
  query,
  getDocs,
  getDoc,
  onSnapshot,
  where,
  doc,
  updateDoc,
  arrayUnion,
  setDoc,
  deleteDoc,
} from "firebase/firestore";

import yourYoutubeImage from "../assets/YoutubeImg.jpg"; // replace with your actual image path

const minCardWidth = 200; // Set this to the desired minimum card width

const MainGame = ({ navigation }) => {
  const { isDemo } = useContext(AppContext); // Access demo mode state

  const [render, setRender] = useState([]);
  const [players, setPlayers] = useState([]);
  const [filter, setFilter] = useState("");
  //const [filteredLeaderboard, setFilteredLeaderboard] = useState(null);

  const [currentUserEmail, setCurrentUserEmail] = useState("");
  const [currentUserId, setCurrentUserId] = useState("");
  const [roomId, setRoomId] = useState("");
  const [loading, setLoading] = useState(true); // Add this to your state


  const screenWidth = Dimensions.get("window").width;
  const [dimensions, setDimensions] = useState(Dimensions.get("window"));

  useEffect(() => {
    const onChange = ({ window }) => {
      setDimensions(window);
    };

    Dimensions.addEventListener("change", onChange);

    return () => {
      // andriod bug fix
      //Dimensions.removeEventListener("change", onChange);
    };
  }, []);

  const numColumns = Math.floor(dimensions.width / minCardWidth);
  const cardWidth = dimensions.width / numColumns - 20;

  const handleFilter = (text) => {
    setFilter(text);
  };


  useEffect(() => {
    if (!currentUserEmail) return; // Don't fetch if currentUserEmail is not ready
  
    const unsubscribe = onSnapshot(
      collection(database, "MainGame"),
      (snapshot) => {
        const sortedGames = snapshot.docs
          .map((doc) => ({ ...doc.data(), id: doc.id }))
          .sort((a, b) => a.created.toDate() - b.created.toDate());
  
        const filteredGames = sortedGames.filter((item) => {
          return (
            item.memberOfGame &&
            item.memberOfGame.some((member) => {
              return member.email === currentUserEmail;
            })
          );
        });
  
        setRender(filteredGames); // Only set filtered games
        setLoading(false); // Set loading to false after filtering
      }
    );
  
    return () => {
      unsubscribe();
    };
  }, [currentUserEmail]); // Depend on currentUserEmail being ready
  

  useEffect(() => {
    if (!auth.currentUser) {
      console.log("No user is signed in. Fetching demo games...");
      const demoGames = [
        {
          id: "IVKgDlA9YCVGJN19m8hx", // Hardcoded game ID
          name: "Demo Game",
          mode: "Standard",
          gameDesc: "This is a demo game for guest users.",
          memberOfGame: [
            {
              uid: "N3x3ewRC8SUx5DScKJ5Q95vMsr23", // Hardcoded userUid for the guest user
              name: "Guest User",
              email: "guest@user.com",
              isAdmin: true,
              role: "verified",
              joinedAt: new Date(),
            },
          ],
        },


        {
          id: "QAvdTVfxYExCDdehjQYi", // Hardcoded game ID
          name: "Demo Knockout",
          mode: "Standard",
          gameDesc: "This is a demo game for guest users.",
          memberOfGame: [
            {
              uid: "N3x3ewRC8SUx5DScKJ5Q95vMsr23", // Hardcoded userUid for the guest user
              name: "Guest User",
              email: "guest@user.com",
              isAdmin: true,
              role: "verified",
              joinedAt: new Date(),
            },
          ],
        },

        {
          id: "demo2",
          name: "Demo Game 2",
        // created: new Date(),
          mode: "Knockout",
          gameDesc: "Another demo game for guest users.",
          memberOfGame: [{ email: "demo@user.com" }],
        },
      ];
      setRender(demoGames); // Set render to demo games
      setLoading(false); // Stop spinner
      return;
    }
  
    console.log("Authenticated user. Fetching user games...");
    setCurrentUserEmail(auth.currentUser.email);
  
    const unsubscribe = onSnapshot(
      collection(database, "MainGame"),
      (snapshot) => {
        const sortedGames = snapshot.docs
          .map((doc) => ({ ...doc.data(), id: doc.id }))
          .sort((a, b) => a.created.toDate() - b.created.toDate());
  
        const filteredGames = sortedGames.filter((item) =>
          item.memberOfGame?.some((member) => member.email === auth.currentUser.email)
        );
  
        setRender(filteredGames); // Set render to filtered games
        setLoading(false); // Stop spinner
      }
    );
  
    return () => {
      unsubscribe();
    };
  }, [auth.currentUser]);
  





  const getFontSize = (text) => {
    const length = text.length;

    if (length <= 10) {
      return 20;
    } else if (length <= 15) {
      return 18;
    } else {
      return 16;
    }
  };

  

  const TestButton = ({ onPress }) => (
    <TouchableOpacity style={styles.gameButton} onPress={onPress}>
      <Text style={{ color: "white", fontSize: 10 }}>Enter</Text>
    </TouchableOpacity>
  );

   
  const filteredLeaderboard = useMemo(() => {
    // If no authenticated user, return all demo games
    if (!auth.currentUser) {
      return render; // Render all demo games without filtering
    }
  
    // If authenticated, filter based on currentUserEmail
    const lowercaseFilter = filter.toLowerCase();
    if (!lowercaseFilter || lowercaseFilter.length === 0) {
      return render.filter((item) =>
        item.memberOfGame?.some((member) => member.email === currentUserEmail)
      );
    }
  
    return render.filter(
      (item) =>
        item.name?.toLowerCase().includes(lowercaseFilter) &&
        item.memberOfGame?.some((member) => member.email === currentUserEmail)
    );
  }, [render, filter, currentUserEmail]);
  
  

  const renderItem = ({ item, index }) => {
    console.log("MainGame mode:", item.mode); // Log the mode field for debugging
  
    return (
      <TouchableOpacity
        style={[styles.gameListWrapper(cardWidth), { margin: 10 }]}
        onPress={() => {
          if (item.mode === "Tournament") {
            navigation.navigate("Tournament", {
              tournamentId: item.id,
              tournamentName: item.name,
              roomId: item.roomId,
              description: item.gameDesc,
              participantName: players.length > 0 ? players[0]?.name : "",
            });
          } else {
            navigation.navigate("Game Date", {
              MainGameId: item.id,
              gameName: item.name,
              roomId: item.roomId,
              gameDescription: item.gameDesc,
              playerName: players.length > 0 ? players[0]?.name : "",
            });
          }
        }}
      >
        <View style={styles.gameListItem}>
          <Text style={styles.creationDate}>
            {index + 1}. Created:{" "}
            {item.created && item.created.toDate().toLocaleDateString()}
          </Text>
          <Image
            source={{ uri: `https://picsum.photos/seed/${item.id}/200/200` }}
            style={styles.cardImage}
            resizeMode="cover"
          />
          <View style={styles.cardContent}>
            <Text
              style={[styles.gameTitle, { fontSize: getFontSize(item.name) }]}
            >
              {item.name}
            </Text>
            <TestButton
              onPress={() => {
                console.log("MainGame mode:", item.mode); // Log again for debugging
                if (item.mode === "Tournament") {
                  navigation.navigate("Tournament", {
                    tournamentId: item.id,
                    tournamentName: item.name,
                    roomId: item.roomId,
                    description: item.gameDesc,
                    participantName: players.length > 0 ? players[0]?.name : "",
                  });
                } else {
                  navigation.navigate("Game Date", {
                    MainGameId: item.id,
                    gameName: item.name,
                    roomId: item.roomId,
                    gameDescription: item.gameDesc,
                    playerName: players.length > 0 ? players[0]?.name : "",
                  });
                }
              }}
            />
          </View>
        </View>
      </TouchableOpacity>
    );
  };
  




  const joinGame = useCallback(async () => {
    // Query to find game with given roomId
    const gameRef = collection(database, "MainGame");
    const q = query(gameRef, where("roomId", "==", roomId));
    const querySnapshot = await getDocs(q);

    // If no game is found, show an error message
    if (querySnapshot.empty) {
      //console.log("No game with this roomId found.");
      return;
    }

    // Get the first game document that matches the roomId
    // (Assuming roomId is unique, there should be only one)
    const gameDoc = querySnapshot.docs[0];

    const playerName = players.length > 0 ? players[0]?.name : "";
    const playerInfo = {
      uid: getAuth()?.currentUser.uid,
      email: getAuth()?.currentUser.email,
      name: playerName,
      role: "viewer", // Set the user's role to 'viewer'
    };

    const docRef = doc(database, "MainGame", gameDoc.id);
    const snapshot = await getDoc(docRef);
    const gameData = snapshot.data();

    let updatedMembers = gameData.memberOfGame || [];
    if (!updatedMembers.some((member) => member.email === playerInfo.email)) {
      updatedMembers.push(playerInfo); // Add new user as 'viewer'
    } else {
      // Update existing member with 'viewer' role
      updatedMembers = updatedMembers.map((member) =>
        member.email === playerInfo.email
          ? { ...member, role: "viewer" }
          : member
      );
    }

    await updateDoc(docRef, {
      memberOfGame: updatedMembers,
    });

    // Navigate to the game screen
    navigation.navigate("Game Date", {
      MainGameId: gameDoc.id,
      gameName: gameData.name,
      gameDescription: gameData.description,
      playerName: playerName,
    });
  }, [roomId, players, navigation]);

  const searchInputRef = React.useRef(null);

 
return (
  <SafeAreaView style={styles.container}>
    <View style={styles.headerContent}>
      <View style={styles.textSection}>
        <Text style={styles.headerText}>Welcome to TeamPlay</Text>       

        <Text
          style={{
            marginLeft: 5,
            fontStyle: "italic",
            color: "blue",
            fontSize: 13,
          }}
        >
          Only host can invite to current game. 
        </Text>
      </View>

      <Pressable
      onPress={() => Linking.openURL("https://youtu.be/_tVMabkKJXg")}
      >
        <View style={styles.tutorialContainer}>
          <Image source={yourYoutubeImage} style={styles.youtubeImage} />
          <Text style={styles.tutorialText}>Tutorial</Text>
        </View>
      </Pressable>
    </View>

    <View>
      {/* <View style={styles.joinGameRow}>
        <TextInput
          style={styles.roomIdInput}
          placeholder="Enter Room ID"
          value={roomId}
          onChangeText={setRoomId}
        />
        <Pressable style={styles.joinGameButton} onPress={joinGame}>
          <Text style={styles.buttonText}>Join Game</Text>
        </Pressable> */}
      {/* </View> */}

      <View style={styles.buttonContainer}>
        <Pressable
          style={styles.makeGameButton}
          onPress={() => navigation.navigate("Make Game")}
        >
          <Text style={styles.buttonText}>Create New Game</Text>
        </Pressable>
      </View>

      <TouchableOpacity
        style={styles.searchContainer}
        onPress={() => searchInputRef.current.focus()}
      >
        <MaterialIcons name="search" size={24} color="#aaa" />
        <TextInput
          ref={searchInputRef}
          style={styles.searchInput}
          placeholder="Search my games"
          value={filter}
          onChangeText={handleFilter}
        />
      </TouchableOpacity>

      <Text style={styles.myGamesTitle}>My Games</Text>
    </View>

    {/* New logic handling loading and filtering */}
    {loading ? (
  <ActivityIndicator size={50} color="#0000ff" />
) : (
  <FlatList
    data={filteredLeaderboard} // Correctly render filteredLeaderboard
    renderItem={renderItem}
    keyExtractor={(item) => item.id}
    numColumns={numColumns}
  />
)}

  </SafeAreaView>
);


};

export default MainGame;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },

  searchIcon: {
    padding: 10,
    flexDirection: "row-reverse",

    borderColor: "green",
    borderWidth: 3,
    justifyContent: "center",
    height: 50,
    borderRadius: 13,
    borderBottomWidth: 2,
    backgroundColor: "#fff",
  },

  userInfo: {
    color: "#333",
    padding: 10,
    fontWeight: "bold",
  },

  myGamesTitle: {
    fontSize: 24,
    fontWeight: "bold",
    color: "#333",
    marginVertical: 10,
    marginLeft: 10,
    alignSelf: "center",
  },

  gameListWrapper: (cardWidth) => ({
    // Update the style object to accept cardWidth as an argument
    flexDirection: "column",
    justifyContent: "space-around",
    width: cardWidth,
    margin: 10,
    backgroundColor: "#fff", // Add a background color

    ...Platform.select({
      ios: {
        shadowColor: "#000",
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
      },
      android: {
        elevation: 5,
      },
    }),
  }),

  gameList: {
    flex: 1,
    padding: 10,
    backgroundColor: "#fff",
    borderRadius: 5,
    //marginRight: 80,
  },

  gameListItem: {
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    margin: 10,
    //padding: 20,
   // height: Platform.OS === "ios" ? 250 : 250, // Increase the height of the card
  },

  gameTitle: {
    fontSize: 20,
    fontWeight: "bold",
    textAlign: "center",
    width: "100%",
    //paddingHorizontal: 5,
    marginBottom: 0, // Add margin to separate it from the image
  },

  cardContent: {
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    flex: 1, // Ensure the content takes up the available space

  },

  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginVertical: 10,
    marginHorizontal: 20,
  },

  headerContent: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%", // occupy full width
  },

  textSection: {
    flex: 1, // to take up as much space as possible
  },

  headerText: {
    fontSize: 16,
    fontWeight: "bold",
  },

  searchContainer: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#fff",
    borderWidth: 1,
    borderColor: "#ccc",
    borderRadius: 5,
    marginHorizontal: 10,
    marginVertical: 5,
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderColor: "blue",
  },

  searchInput: {
    flex: 1,
  },

  gameButton: {
    paddingVertical: Platform.OS === "web" ? 10 : 10,
    paddingHorizontal: Platform.OS === "web" ? 20 : 10,
    margin: 0,
    //marginBottom: 25,
    //paddingBottom: 25,
    //marginBottom: 25,
    borderRadius: 50,
    backgroundColor: "#4681f4",
    //width: "100%", // Make the button take the full width of the card content

  },

  cardImage: {
    width: "100%",
    height: 100,
    //resizeMode: "cover",
    borderRadius: 5,
    marginBottom: 10,
  },

  joinGameRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 5,
    backgroundColor: "#f8f8f8",
    ...(Platform.OS === "android" ? { marginTop: 0 } : {}), // Add extra top margin on Android
  },
  roomIdInput: {
    flex: 3, //  flex value to take more space
    height: 40,
    borderColor: "gray",
    borderWidth: 1,
    borderRadius: 5,
    padding: 10,
    marginRight: 10, // Optional, for spacing between the input and the button
  },
  joinGameButton: {
    flex: 1, // Smaller flex value to take less space
    backgroundColor: "#007BFF",
    height: 40,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
  },

 

  roomIdInput: {
    flex: 2,
    height: 40,
    borderColor: "gray",
    borderWidth: 1,
    borderRadius: 5,
    padding: 10,
  },

  buttonContainer: {
    flexDirection: "row", // Arrange buttons in a row
    justifyContent: "space-around", // Distribute buttons evenly
    padding: 10, // Add some padding
  },

  makeGameButton: {
    backgroundColor: "#8E4585", // "#007BFF",
    height: 40,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
    padding: 10,
    flex: 1, // Make the button fill half of the container
  },

  beerCounterButton: {
    backgroundColor: "#008000", // green color
    height: 40,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
    padding: 10,
    flex: 1, // Make the button fill half of the container
  },

  buttonText: {
    color: "#FFFFFF",
    fontSize: 16,
    textAlign: "center",
  },

  tutorialContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
    padding: 0,
    ...(Platform.OS === "android" ? { marginBottom: 0 } : {}), // Increase bottom margin on Android
  },

  youtubeImage: {
    width: 160, // 16*10, adjust width as per your UI
    height: 90, // 9*10, adjust height with respect to width to maintain 16:9 aspect ratio
    alignSelf: "flex-end",
    borderRadius: 10, // Optionally add some border radius if you want rounded corners
  },

  tutorialText: {
    position: "absolute", // Use absolute positioning
    bottom: 10, // Position it 10px from the bottom
    left: 10, // And 10px from the left
    color: "white", // White text
    fontSize: 20, //  font size
    fontWeight: "bold", // Bold font weight
    textShadowColor: "rgba(0, 0, 0, 0.75)", // Add text shadow for better readability
    textShadowOffset: { width: -1, height: 1 },
    textShadowRadius: 10,
  },

  testButton: {
    padding: 10,
    backgroundColor: "blue",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 5,
    // Add more styling as needed
  },

  testButtonText: {
    color: "white",
    fontSize: 16,
    // Add more styling as needed
  },

  creationDate: {
    fontSize: 15,
    color: "#666",
    textAlign: "center",
    marginBottom: 5,
  },


});
