// Tournament Scheduler

import React, { useState } from 'react';
import { View, Text, TextInput, Button, StyleSheet, ScrollView } from 'react-native';
import moment from 'moment';

const TournamentScheduler = () => {
  // States
  const [numTournaments, setNumTournaments] = useState(2); // Set programmatically to 2
  const [teamsPerTournament, setTeamsPerTournament] = useState([
    22, // Number of teams for the first tournament
    24, // Number of teams for the second tournament
  ]);
  const [groupsPerTournament, setGroupsPerTournament] = useState([
    4, // Number of groups for the first tournament
    6, // Number of groups for the second tournament
  ]);
  const [numPitches, setNumPitches] = useState('2');
  const [gameDuration, setGameDuration] = useState('30');
  const [pauseDuration, setPauseDuration] = useState('10');
  const [largestTournamentStartTime, setLargestTournamentStartTime] = useState('09:00');
  const [schedule, setSchedule] = useState([]);

  const teams = {
    larger: [
      'Íslandsbanki 1', 'TM', 'PwC', 'Arion 2', 'ÍV/T Plús/Stapi', 'Íslandsbanki 2',
      'Kvika', 'Landsbankinn 1', 'Arion 3', 'Seðlabankinn', 'Teya', 'Arion 1',
      'Leiguskjól', 'Acro', 'Landsbankinn 2', 'Íslandsbanki 3', 'HMS', 'Deloitte',
      'Sjóvá', 'Landsbankinn 3', 'Skagi', 'Íslandsbanki 4'
    ],
    smaller: [
      'Deloitte', 'Landsbankinn 3', 'Sjóvá', 'Skagi', 'Íslandsbanki 4', 'HMS',
      'Íslandsbanki 4', 'Landsbankinn 3', 'Sjóvá', 'HMS', 'Skagi', 'Deloitte',
      'HMS', 'Deloitte', 'Landsbankinn 3', 'Íslandsbanki 4', 'Sjóvá', 'HMS',
      'Skagi', 'Skagi', 'Sjóvá', 'Landsbankinn 3', 'HMS', 'Landsbankinn 3',
      'Íslandsbanki 4'
    ]
  };

  const suggestGroups = (numTeams) => {
    if (numTeams <= 4) return 1;
    return Math.max(1, Math.floor(numTeams / 4));
  };

  const generateSchedule = () => {
    console.log('Generate Schedule clicked');
    if (
      teamsPerTournament.some((num) => !num) ||
      groupsPerTournament.some((num) => !num) ||
      !numPitches ||
      !gameDuration ||
      !pauseDuration ||
      !largestTournamentStartTime
    ) {
      alert('Please fill out all fields and ensure each tournament has a number of teams and groups specified.');
      return;
    }

    const parsedGameDuration = parseInt(gameDuration);
    const parsedPauseDuration = parseInt(pauseDuration);
    const allSchedules = [];

    const gamesPerTournament = teamsPerTournament.map((teams, index) => {
      const groups = groupsPerTournament[index];
      const teamsPerGroup = teams / groups;

      const gamesPerGroup = (teamsPerGroup * (teamsPerGroup - 1)) / 2;
      return gamesPerGroup * groups;
    });

    console.log('Games per tournament:', gamesPerTournament);

    const largestTournamentIndex = gamesPerTournament.indexOf(Math.max(...gamesPerTournament));
    const largestTournamentStart = moment(largestTournamentStartTime, 'HH:mm');

    for (let tournament = 0; tournament < numTournaments; tournament++) {
      let currentTime;
      if (tournament === largestTournamentIndex) {
        currentTime = largestTournamentStart.clone();
      } else {
        currentTime = largestTournamentStart.clone();
      }

      const numberOfTeams = teamsPerTournament[tournament];
      const numberOfGroups = groupsPerTournament[tournament];

      if (numberOfTeams / numberOfGroups < 4) {
        alert(`Tournament ${tournament + 1} has too few teams per group. Ensure at least 4 teams per group.`);
        return;
      }

      const games = [];

      for (let group = 1; group <= numberOfGroups; group++) {
        const groupTeams = [];
        for (let i = 0; i < numberOfTeams / numberOfGroups; i++) {
          groupTeams.push(`Tournament ${tournament + 1} - Team ${i + 1 + (group - 1) * (numberOfTeams / numberOfGroups)}`);
        }

        for (let i = 0; i < groupTeams.length; i++) {
          for (let j = i + 1; j < groupTeams.length; j++) {
            games.push({
              group: `Group ${group}`,
              team1: groupTeams[i],
              team2: groupTeams[j],
            });
          }
        }
      }

      console.log(`Games for Tournament ${tournament + 1}:`, games);

      let gameIndex = 0;
      const tournamentSchedule = [];
      while (gameIndex < games.length) {
        for (let pitch = 1; pitch <= numPitches && gameIndex < games.length; pitch++) {
          const game = games[gameIndex];
          tournamentSchedule.push({
            tournament: tournament + 1,
            game: gameIndex + 1,
            group: game.group,
            pitch: `Pitch ${pitch}`,
            time: currentTime.format('HH:mm'),
            teams: [game.team1, game.team2],
          });
          gameIndex++;
        }
        currentTime = currentTime.add(parsedGameDuration + parsedPauseDuration, 'minutes');
      }

      allSchedules.push(tournamentSchedule);
    }

    const unifiedSchedule = allSchedules.flat();
    unifiedSchedule.sort((a, b) => {
      const timeA = moment(a.time, 'HH:mm');
      const timeB = moment(b.time, 'HH:mm');
      return timeA.diff(timeB);
    });

    console.log('Unified Schedule:', unifiedSchedule);

    const groupedByPitches = {};
    for (let i = 1; i <= numPitches; i++) {
      groupedByPitches[`Pitch ${i}`] = unifiedSchedule.filter((game) => game.pitch === `Pitch ${i}`);
    }

    console.log('Grouped by Pitches:', groupedByPitches);
    setSchedule(groupedByPitches);
  };

  return (
    <ScrollView style={styles.container}>
      <Text style={styles.header}>Tournament Scheduler</Text>

      <Text style={styles.label}>Number of Tournaments</Text>
      <TextInput
        style={styles.input}
        value="2" // Hardcoded
        editable={false}
      />

      {Object.keys(teams).map((key, index) => (
        <View key={index} style={styles.tournamentInputContainer}>
          <Text style={styles.label}>Teams in Tournament {index + 1}</Text>
          {teams[key].map((team, idx) => (
            <Text key={idx} style={styles.teamItem}>{team}</Text>
          ))}

          <Text style={styles.label}>Number of Groups in Tournament {index + 1} (Suggested: {suggestGroups(teams[key].length)})</Text>
          <TextInput
            style={styles.input}
            keyboardType="numeric"
            value={groupsPerTournament[index]?.toString() || ''}
            placeholder={`Suggested: ${suggestGroups(teams[key].length)}`}
            onChangeText={(value) => {
              const updatedGroups = [...groupsPerTournament];
              updatedGroups[index] = parseInt(value) || '';
              setGroupsPerTournament(updatedGroups);
            }}
          />
        </View>
      ))}

      <Text style={styles.label}>Number of Pitches</Text>
      <TextInput
        style={styles.input}
        keyboardType="numeric"
        value={numPitches}
        onChangeText={setNumPitches}
      />

      <Text style={styles.label}>Game Duration (minutes)</Text>
      <TextInput
        style={styles.input}
        keyboardType="numeric"
        value={gameDuration}
        onChangeText={setGameDuration}
      />

      <Text style={styles.label}>Pause Duration Between Games (minutes)</Text>
      <TextInput
        style={styles.input}
        keyboardType="numeric"
        value={pauseDuration}
        onChangeText={setPauseDuration}
      />

      <Text style={styles.label}>Start Time for Largest Tournament (HH:mm)</Text>
      <TextInput
        style={styles.input}
        value={largestTournamentStartTime}
        onChangeText={setLargestTournamentStartTime}
      />

      <Button title="Generate Schedule" onPress={generateSchedule} />

      <View style={styles.scheduleContainer}>
        {Object.keys(schedule).length > 0 &&
          Object.entries(schedule).map(([pitch, games]) => (
            <View key={pitch} style={styles.tournamentInputContainer}>
              <Text style={styles.label}>{pitch}</Text>
              {games.map((game, index) => (
                <View key={index} style={styles.gameItem}>
                  <Text>
                    {game.time} - Tournament {game.tournament}, {game.group}: {game.teams[0]} vs {game.teams[1]}
                  </Text>
                </View>
              ))}
            </View>
          ))}
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    backgroundColor: '#f5f5f5',
  },
  header: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 20,
  },
  label: {
    fontSize: 16,
    marginTop: 10,
  },
  input: {
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 5,
    padding: 10,
    marginVertical: 10,
    backgroundColor: '#fff',
  },
  tournamentInputContainer: {
    marginBottom: 10,
  },
  teamItem: {
    fontSize: 14,
    marginVertical: 2,
  },
  scheduleContainer: {
    marginTop: 20,
  },
  gameItem: {
    padding: 10,
    backgroundColor: '#fff',
    borderRadius: 5,
    marginBottom: 5,
    borderWidth: 1,
    borderColor: '#ddd',
  },
});

export default TournamentScheduler;
