import React, { useState } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Image } from 'react-native';
import { auth } from '../componenets/firebase'; // Ensure this exports auth from your firebase config

function GameModeSelector({ onModeSelect }) {
  const [selectedMode, setSelectedMode] = useState("team"); // Default to "team"

  // Retrieve the current user's email dynamically from the auth instance.
  const currentUserEmail = auth.currentUser ? auth.currentUser.email : null;

  const selectMode = (mode) => {
    setSelectedMode(mode);
    onModeSelect(mode);
  };

  return (
    <View style={styles.container}>
      {/* Always visible game mode */}
      <TouchableOpacity onPress={() => selectMode('team')}>
        <Image source={require('../assets/Team.png')} style={styles.modeImage} />
        {selectedMode === 'team' && <Text style={styles.checkmark}>✓</Text>}
      </TouchableOpacity>

      {/* Conditionally visible game modes for dondanni@hotmail.com */}
      {(currentUserEmail === 'dondanni@hotmail.com' || currentUserEmail === 'thordisosp@gmail.com') && (
  <>
    <TouchableOpacity onPress={() => selectMode('individual')}>
      <Image source={require('../assets/Individual.png')} style={styles.modeImage} />
      {selectedMode === 'individual' && <Text style={styles.checkmark}>✓</Text>}
    </TouchableOpacity>

    <TouchableOpacity onPress={() => selectMode('beerCounter')}>
      <Image source={require('../assets/Beer_Counter.png')} style={styles.modeImage} />
      {selectedMode === 'beerCounter' && <Text style={styles.checkmark}>✓</Text>}
    </TouchableOpacity>

    <TouchableOpacity onPress={() => selectMode('knockOut')}>
      <Image source={require('../assets/KnockOut.png')} style={styles.modeImage} />
      {selectedMode === 'knockOut' && <Text style={styles.checkmark}>✓</Text>}
    </TouchableOpacity>

    <TouchableOpacity onPress={() => selectMode('Tournament')}>
      <Image source={require('../assets/favicon-science_.png')} style={styles.modeImage} />
      {selectedMode === 'Tournament' && <Text style={styles.checkmark}>✓</Text>}
    </TouchableOpacity>

  </>
)}

    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  modeImage: {
    width: 150,
    height: 150,
    margin: 10,
    borderRadius: 15
  },
  checkmark: {
    position: 'absolute',
    color: 'white',
    fontSize: 50,
    top: '50%',
    left: '50%',
    transform: [{ translateX: -25 }, { translateY: -25 }],
    backgroundColor: 'rgba(0, 128, 0, 0.6)',
    borderRadius: 25,
    width: 50,
    height: 50,
    textAlign: 'center',
  },
});

export default GameModeSelector;
