import React, { useState, useEffect } from 'react';
import { StyleSheet, SafeAreaView, FlatList, Text, View, Button } from 'react-native';
import { collectionGroup, query, where, onSnapshot } from "firebase/firestore";
import { database } from "../componenets/firebase";
import moment from 'moment';

const PvpLeaderBoard = ({ route, navigation }) => {
  const [playerData, setPlayerData] = useState({});
  const [dates, setDates] = useState([]);
  const [showGames, setShowGames] = useState(false); // State to toggle Games List Section
  const { mainGameId } = route.params;

  // Fetch player leaderboard data
  useEffect(() => {
    const fetchPlayersQuery = query(
      collectionGroup(database, 'Players'),
      where('mainGameId', '==', mainGameId)
    );

    const unsubscribe = onSnapshot(fetchPlayersQuery, (querySnapshot) => {
      const data = {};

      querySnapshot.docs.forEach((doc) => {
        const player = doc.data();
        const playerName = player.name || 'Unknown Player';

        if (!data[playerName]) {
          data[playerName] = { totalScore: 0, gamesPlayed: 0 };
        }
        data[playerName].totalScore += player.pvpScore || 0; // Handle missing pvpScore
        data[playerName].gamesPlayed += 1;
      });

      setPlayerData(data);
    });

    return () => {
      unsubscribe();
    };
  }, [mainGameId]);

  // Fetch games with dates and players
  useEffect(() => {
    const fetchPlayersQuery = query(
      collectionGroup(database, 'Players'),
      where('mainGameId', '==', mainGameId)
    );

    const unsubscribe = onSnapshot(fetchPlayersQuery, (querySnapshot) => {
      const playerData = querySnapshot.docs.map((doc) => {
        const player = doc.data();
        player.gameDate = player.gameDate || null;
        return player;
      });

      const dates = playerData.reduce((acc, player) => {
        const { gameDate, gameIndex } = player;
        const dateKey = moment(gameDate).format('YYYY-MM-DD');
        const existingDate = acc.find((dateObj) => dateObj.date === dateKey);

        if (existingDate) {
          const existingGame = existingDate.games.find((game) => game.gameIndex === gameIndex);
          if (existingGame) {
            existingGame.players.push(player);
            existingGame.players.sort((a, b) => {
              if (a.pvpScore !== b.pvpScore) {
                return b.pvpScore - a.pvpScore; // Sort by Pvp score in descending order
              } else {
                return a.name.localeCompare(b.name); // Sort by name in ascending order
              }
            });
          } else {
            existingDate.games.push({ gameIndex, players: [player] });
          }
        } else {
          acc.push({
            date: dateKey,
            games: [{ gameIndex, players: [player] }],
          });
        }
        return acc;
      }, []);

      dates.sort((a, b) => moment(b.date, 'YYYY-MM-DD').diff(moment(a.date, 'YYYY-MM-DD')));

      setDates(dates);
    });

    return () => {
      unsubscribe();
    };
  }, [mainGameId]);

  const PlayerRow = ({ player }) => (
    <View style={styles.playerRow}>
      <Text style={styles.playerName}>{player.name}</Text>
      <Text style={styles.playerScore}>PVP Score: {player.pvpScore}</Text>
    </View>
  );

  const GameCard = ({ date, games }) => (
    <View style={styles.card}>
      <Text style={styles.cardDate}>
        {moment(date, 'YYYY-MM-DD').format('MMMM Do, YYYY')}
      </Text>
      {games.map((game, index) => (
        <View key={`game-${index}`} style={styles.gameCard}>
          <Text style={styles.gameHeader}>Game #{game.gameIndex}</Text>
          {game.players.map((player, playerIndex) => (
            <PlayerRow player={player} key={playerIndex} />
          ))}
        </View>
      ))}
    </View>
  );

  return (
    <SafeAreaView style={styles.container}>
      {/* Leaderboard Section */}
      <FlatList
        data={Object.entries(playerData).sort((a, b) => b[1].totalScore - a[1].totalScore)} // Sort by totalScore descending
        keyExtractor={([name,]) => name}
        ListHeaderComponent={
          <Text style={styles.leaderboardHeader}>PvP Leaderboard</Text>
        }
        renderItem={({ item: [name, { totalScore, gamesPlayed }] }) => (
          <View style={styles.leaderboardRow}>
            <Text style={styles.playerName}>{name}</Text>
            <Text style={styles.totalScore}>Total PvP Score: {totalScore}</Text>
            <Text style={styles.gamesPlayed}>Games Played: {gamesPlayed}</Text>
          </View>
        )}
      />

      {/* Toggle Button */}
      <Button
        title={showGames ? "Hide Game List" : "Show Game List"}
        onPress={() => setShowGames((prev) => !prev)}
      />

      {/* Conditionally Render Games List Section */}
      {showGames && (
        <FlatList
          data={dates}
          keyExtractor={(item, index) => index.toString()}
          renderItem={({ item }) => (
            <GameCard date={item.date} games={item.games} />
          )}
        />
      )}
    </SafeAreaView>
  );
};

export default PvpLeaderBoard;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 10,
    backgroundColor: '#f9f9f9',
  },
  leaderboardHeader: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 15,
    textAlign: 'center',
    color: '#4a4a4a',
  },
  leaderboardRow: {
    backgroundColor: '#fff',
    marginBottom: 10,
    padding: 12, // Reduced padding for a compact row
    borderRadius: 6, // Slightly smaller border radius
    borderWidth: 1,
    borderColor: '#d9d9d9',
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.1,
    shadowRadius: 2,
  },
  playerName: {
    fontSize: 16, // Reduced font size for compact design
    fontWeight: '600',
    color: '#333',
  },
  totalScore: {
    fontSize: 14, // Reduced font size for compact design
    color: '#666',
  },
  gamesPlayed: {
    fontSize: 14, // Reduced font size for compact design
    color: '#666',
  },
  card: {
    backgroundColor: '#fff',
    marginBottom: 8, // Slightly reduced margin between cards
    padding: 12, // Reduced padding for a smaller card size
    borderRadius: 6, // Slightly smaller border radius
    borderWidth: 1,
    borderColor: '#d9d9d9',
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.1,
    shadowRadius: 2,
  },
  cardDate: {
    fontSize: 14, // Smaller font size for date header
    fontWeight: 'bold',
    color: '#4a4a4a',
    marginBottom: 8, // Reduced bottom margin
  },
  gameCard: {
    backgroundColor: '#f9f9f9',
    marginBottom: 6, // Reduced spacing between game cards
    padding: 8, // Reduced padding for a smaller card size
    borderRadius: 4, // Smaller border radius for compact design
    borderWidth: 1,
    borderColor: '#e0e0e0',
  },
  gameHeader: {
    fontSize: 12, // Reduced font size for game headers
    fontWeight: 'bold',
    color: '#333',
    marginBottom: 4, // Reduced bottom margin
  },
  playerRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 3, // Reduced vertical padding for compact rows
  },
  playerScore: {
    fontSize: 12, // Reduced font size for player scores
    color: '#666',
  },
});
