import React, { useContext , useState } from 'react';
import {
  View,
  Text,
  StyleSheet,
  ImageBackground,
  TouchableOpacity,
  Image,
  ScrollView,
  SafeAreaView,
  Alert,
  Platform, // Import Platform
  Modal,
} from 'react-native';
import { useNavigation } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import { AppContext } from './AppContext';

// Replace these with your actual imports/paths:
import yourYoutubeImage from '../assets/YoutubeImg.jpg';
import androidImage from '../assets/google-play-store.png';
import appleImage from '../assets/store-apple.png';
import QRcode from '../assets/QRcode.png';
import Team from '../assets/Team.png';
import Attendance from '../assets/Attendance.png';
import Leaderboard from '../assets/Leaderboard.png';
import Results from '../assets/Results.png';

const Home = () => {
  const navigation = useNavigation();
  const backgroundImage = require('../assets/background.jpg');
  const [isModalVisible, setModalVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const { setIsDemo } = useContext(AppContext);
 
  const handleDemoAccess = () => {
    setIsDemo(true); // Enable demo mode
    console.log('Demo mode enabled'); // Debug statement
    navigation.reset({
      index: 0,
      routes: [{ name: 'Home' }], // Replace with your actual dashboard route name
    });
  };

  const openImageModal = (image) => {
    setSelectedImage(image);
    setModalVisible(true);
  };

  const closeImageModal = () => {
    setModalVisible(false);
  };

  const openAndroidStore = async () => {
    await WebBrowser.openBrowserAsync(
      'https://play.google.com/store/apps/details?id=cool.teamplay.TeamPlay'
    );
  };

  const openAppleStore = async () => {
    await WebBrowser.openBrowserAsync(
      'https://apps.apple.com/is/app/teamplayarena/id6473748187'
    );
  };

  const openYoutube = async () => {
    await WebBrowser.openBrowserAsync('https://youtu.be/G2rrj7zkoAQ');
  };

  return (
    <SafeAreaView style={styles.container}>
      <ImageBackground 
        source={backgroundImage} 
        style={styles.background} 
        resizeMode="cover"
      >
        <ScrollView contentContainerStyle={styles.scrollView}>
          <View style={styles.header}>
            <Text style={styles.title}>Welcome to TeamPlay.cool</Text>

            <View style={styles.buttons}>
              <TouchableOpacity
                style={styles.button}
                onPress={() => navigation.navigate('Sign Up')}
              >
                <Text style={styles.buttonText}>Sign Up (First Time)</Text>
              </TouchableOpacity>

              <TouchableOpacity
                style={styles.button}
                onPress={() => navigation.navigate('Sign In')}
              >
                <Text style={styles.buttonText}>Log In</Text>
              </TouchableOpacity>

                 <TouchableOpacity
                style={styles.buttondemo}
                onPress={handleDemoAccess}
              >
                <Text style={styles.buttonTextdemo}>Demo</Text>
              </TouchableOpacity>

            </View>

            {/* YouTube preview */}
            <View style={styles.youtubeContainer}>
              <Text style={styles.youtubeText}>
                Tap the image to watch our introduction
              </Text>
              <TouchableOpacity onPress={openYoutube}>
                <Image 
                  source={yourYoutubeImage} 
                  style={styles.youtubeImage} 
                />
              </TouchableOpacity>
            </View>

       

            {Platform.OS === 'web' && (
              <View style={styles.qrContainer}>
                <Text style={styles.qrText}>Scan the QR code to download on Android or iOS</Text>
                <Image
                  source={QRcode}
                  style={styles.qrImage}
                  resizeMode="contain"
                />
              </View>
            )}

            <Text style={styles.qrText}> Or click here if you’re on Android or iOS. </Text>

            {Platform.OS === 'android' && (
              <View style={styles.singleBadgeContainer}>
                <TouchableOpacity onPress={openAndroidStore}>
                  <Image
                    source={androidImage}
                    style={styles.storeBadgeImage}
                    resizeMode="contain"
                  />
                </TouchableOpacity>
              </View>
            )}

            {Platform.OS === 'ios' && (
              <View style={styles.singleBadgeContainer}>
                <TouchableOpacity onPress={openAppleStore}>
                  <Image
                    source={appleImage}
                    style={styles.storeBadgeImage}
                    resizeMode="contain"
                  />
                </TouchableOpacity>
              </View>
            )}

            {Platform.OS !== 'android' && Platform.OS !== 'ios' && (
              <View style={styles.storeBadgesContainer}>
                <TouchableOpacity onPress={openAndroidStore}>
                  <Image
                    source={androidImage}
                    style={styles.storeBadgeImage}
                    resizeMode="contain"
                  />
                </TouchableOpacity>
                <TouchableOpacity onPress={openAppleStore}>
                  <Image
                    source={appleImage}
                    style={styles.storeBadgeImage}
                    resizeMode="contain"
                  />
                </TouchableOpacity>
              </View>
            )}

          </View>


               {/* Leaderboard and Results section */}
               <View style={styles.infoSectionContainer}>
              <Text style={styles.infoSectionHeader}>Learn More About the App</Text>
              <Text style={[styles.infoSectionText, styles.imageDescription]}>
                Below are images that explain how the app works, showcasing the Leaderboard and Results features.
              </Text>

              {/* Attendance image */}
              <View style={styles.infoImageContainer}>
                <TouchableOpacity onPress={() => openImageModal(Attendance)}>
                  <Image 
                    source={Attendance} 
                    style={styles.infoImage} 
                    resizeMode="contain"
                  />
                </TouchableOpacity>
                <Text style={styles.imageDescription}>Track attendance with ease using this feature.</Text>
              </View>

       

              {/* Results image */}
              <View style={styles.infoImageContainer}>
                <TouchableOpacity onPress={() => openImageModal(Results)}>
                  <Image 
                    source={Results} 
                    style={styles.infoImage} 
                    resizeMode="contain"
                  />
                </TouchableOpacity>
                <Text style={styles.imageDescription}>Keep track of game results with this detailed view.</Text>
              </View>

                     {/* Leaderboard image */}
                     <View style={styles.infoImageContainer}>
                <TouchableOpacity onPress={() => openImageModal(Leaderboard)}>
                  <Image 
                    source={Leaderboard} 
                    style={styles.infoImage} 
                    resizeMode="contain"
                  />
                </TouchableOpacity>
                <Text style={styles.imageDescription}>View and compare your scores on the Leaderboard.</Text>
              </View>

              
            </View>



        </ScrollView>



        {/* Modal for large image */}
        <Modal
          visible={isModalVisible}
          transparent={true}
          animationType="fade"
          onRequestClose={closeImageModal}
        >
          <View style={styles.modalContainer}>
            <TouchableOpacity style={styles.modalCloseButton} onPress={closeImageModal}>
              <Text style={styles.modalCloseText}>Close</Text>
            </TouchableOpacity>
            <Image source={selectedImage} style={styles.modalImage} resizeMode="contain" />
          </View>
        </Modal>
      </ImageBackground>
    </SafeAreaView>
  );
};

export default Home;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  background: {
    flex: 1,
  },
  scrollView: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  header: {
    alignItems: 'center',
    margin: 20,
  },
  title: {
    fontSize: 28,
    fontWeight: 'bold',
    color: 'white',
    textAlign: 'center',
    marginTop: 20,
    padding: 10,
    borderRadius: 10,
  },
  buttons: {
    marginTop: 20,
  },
  button: {
    backgroundColor: '#007BFF',
    padding: 12,
    borderRadius: 8,
    marginTop: 10,
    width: 250,
    alignItems: 'center',
  },
  buttonText: {
    color: 'white',
    fontSize: 18,
  },
  singleBadgeContainer: {
    marginVertical: 20,
  },
  storeBadgesContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 20,
  },
  storeBadgeImage: {
    width: 160,
    height: 50,
    marginHorizontal: 10,
  },
  youtubeContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 50,
  },
  youtubeText: {
    marginVertical: 20,
    fontSize: 16,
    color: 'white',
    textAlign: 'center',
    backgroundColor: 'rgba(0,0,0,0.6)',
    padding: 10,
    borderRadius: 8,
  },
  youtubeImage: {
    width: 300,
    height: 200,
    borderRadius: 10,
  },
  qrContainer: {
    marginVertical: 20,
    alignItems: 'center',
  },
  qrText: {
    fontSize: 16,
    color: 'white',
    marginBottom: 10,
    backgroundColor: 'rgba(0,0,0,0.7)', // adjust as desired
    paddingHorizontal: 8,
    paddingVertical: 4,
    borderRadius: 6,
    textAlign: 'center',
  },
  qrImage: {
    width: 350,
    height: 350,
  },
  attendanceContainer: {
    marginVertical: 20,
    alignItems: 'center',
  },
  attendanceImage: {
    width: 300,
    height: 200,
  },
  infoSectionContainer: {
    marginVertical: 30,
    paddingHorizontal: 20,
    alignItems: 'center',
  },
  infoSectionHeader: {
    fontSize: 24,
    fontWeight: 'bold',
    color: 'white',
    marginBottom: 10,
    textAlign: 'center',
    backgroundColor: 'rgba(0,0,0,0.6)',
  },
  infoSectionText: {
    fontSize: 16,
    color: 'white',
    textAlign: 'center',
    marginBottom: 20,
  },
  infoImageContainer: {
    marginVertical: 15,
    alignItems: 'center',
  },
  infoImage: {
    width: 300,
    height: 200,
  },
  imageDescription: {
    marginTop: 10,
    fontSize: 14,
    color: 'white',
    textAlign: 'center',
    backgroundColor: 'rgba(0,0,0,0.6)',
    paddingHorizontal: 8,
    paddingVertical: 4,
    borderRadius: 6,
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
  modalImage: {
    width: '90%',
    height: '70%',
  },
  modalCloseButton: {
    position: 'absolute',
    top: 40,
    right: 20,
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    padding: 10,
    borderRadius: 20,
  },
  modalCloseText: {
    color: 'black',
    fontSize: 16,
    fontWeight: 'bold',
  },
  buttondemo: {
    backgroundColor: 'orange', // Golden yellow for demo
    paddingVertical: 12,
    paddingHorizontal: 24,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5, // For Android shadow
    margin: 10, // Add spacing around the button
  },
  buttonTextdemo: {
    color: '#333', // Darker text for contrast
    fontSize: 18,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
});
