// TheGame.js

import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  SafeAreaView,
  FlatList,
  StyleSheet,
  Pressable,
  Image,
  Alert,
  Platform,
} from "react-native";

import { getAuth } from "firebase/auth";
import { database } from "../componenets/firebase";
import {
  collection,
  query,
  onSnapshot,
  doc,
  getDoc,
  getDocs,
  deleteDoc,
} from "firebase/firestore";
import Icon from "react-native-vector-icons/FontAwesome";
import { MaterialIcons } from "@expo/vector-icons";
import moment from "moment";
import * as Clipboard from "expo-clipboard";

const TheGame = ({ navigation, route }) => {
  const [mainGameId, setmainGameId] = useState(route.params.MainGameId);
  const [gameName, setGameName] = useState(route.params.gameName);
  const [roomId, setRoomId] = useState(route.params.roomId);
  const [theGame, setTheGame] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [imgSrc, setImgSrc] = React.useState(null);
  const [isViewer, setIsViewer] = useState(false);

  const [currentUserEmail, setCurrentUserEmail] = useState(null); // Store the current user's email

  // Fetch current user and set email state
  useEffect(() => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      setCurrentUserEmail(user.email); // Set the user's email in state
    } else {
      console.error("User not found or not authenticated.");
    }
  }, []); // Empty dependency array ensures this runs only once on component mount

  const gameModes = [
    { name: "individual", imgSrc: require("../assets/Individual.png") },
    { name: "team", imgSrc: require("../assets/Team.png") },
    { name: "beerCounter", imgSrc: require("../assets/Beer_Counter.png") },
    { name: "knockOut", imgSrc: require("../assets/KnockOut.png") },
     { name: "Tournament", imgSrc: require("../assets/favicon-science_.png") },
  ];

  const copyToClipboard = () => {
    Clipboard.setString(roomId);
    alert("Room Id - Copied to clipboard");
  };

  const [gameMode, setGameMode] = useState(null);

  const deleteDay = (TheGameId) => {
    if (Platform.OS === "web") {
      const isConfirmed = window.confirm("Are you sure you want to delete?");
      if (isConfirmed) {
        handleDeletion(TheGameId);
      }
    } else {
      Alert.alert("Confirm Deletion", "Are you sure you want to delete?", [
        {
          text: "Cancel",
          onPress: () => console.log("Deletion cancelled"),
          style: "cancel",
        },
        {
          text: "OK",
          onPress: () => handleDeletion(TheGameId),
        },
      ]);
    }
  };

  const handleDeletion = async (TheGameId) => {
    try {
      const docRef = doc(
        database,
        "MainGame",
        mainGameId,
        "TheGame",
        TheGameId
      );

      const deleteSubCollection = async (subCollectionName) => {
        const subCollectionRef = collection(docRef, subCollectionName);
        const querySnapshot = await getDocs(subCollectionRef);
        const deletionPromises = [];
        querySnapshot.forEach((subDoc) => {
          const subDocRef = doc(
            database,
            "MainGame",
            mainGameId,
            "TheGame",
            TheGameId,
            subCollectionName,
            subDoc.id
          );
          deletionPromises.push(deleteDoc(subDocRef));
        });
        await Promise.all(deletionPromises);
      };

      await deleteSubCollection("Players");
      await deleteSubCollection("Messages");

      await deleteDoc(docRef);
    } catch (error) {
      console.error("Error deleting game and its subcollections: ", error);
    }
  };

  useEffect(() => {
    const fetchIsAdminStatus = async () => {
      try {
        const mainGameDoc = doc(database, "MainGame", mainGameId);
        const mainGameSnapshot = await getDoc(mainGameDoc);
        const { memberOfGame, mode } = mainGameSnapshot.data();

        setGameMode(mode);

        const gameModeData = gameModes.find((gm) => gm.name === mode);
        if (gameModeData) {
          setImgSrc(gameModeData.imgSrc);
        }

        const currentUser = getAuth().currentUser;

        if (currentUser) {
          const currentUserId = currentUser.uid;

          const currentUserData = memberOfGame.find(
            (member) => member.uid === currentUserId
          );

          if (currentUserData) {
            setIsAdmin(currentUserData.isAdmin === true);
            setIsViewer(currentUserData.role === "viewer");
          }
        }
      } catch (error) {
        console.error("Error in fetchIsAdminStatus:", error);
      }
    };

    fetchIsAdminStatus();
  }, []);

  useEffect(() => {
    const gameCollectionRef = collection(
      database,
      "MainGame",
      mainGameId,
      "TheGame"
    );
    const q = query(gameCollectionRef);

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      let list = [];
      querySnapshot.forEach((doc) => {
        const { Name, date, gameTitle, index, time, location } = doc.data();
        list.push({
          id: doc.id,
          Name,
          date: new Date(date),
          gameTitle,
          index,
          time,
          location,
        });
      });

      list.sort((a, b) => {
        const dateComparison = b.date - a.date;
        if (dateComparison !== 0) return dateComparison;
        return b.index - a.index;
      });

      setTheGame(list);
    });

    return unsubscribe;
  }, []);

  const renderItem = ({ item: TheGame }) => (
    <View style={styles.listItemContainer}>
      <Pressable
        style={styles.listItem}
        onPress={() => {
          let navigateTo = "Text";
          const navigationParams = {
            TheGameId: TheGame.id,
            mainGameId: mainGameId,
            GameDate: TheGame.date.toISOString(),
            GameIndex: TheGame.index,
            GameName: gameName,
            location: TheGame.location,
            time: TheGame.time,
          };

          switch (gameMode) {
            case "team":
              navigateTo = "Players";
              break;
            case "beerCounter":
              navigateTo = "BeerCounter";
              break;
            case "individual":
              navigateTo = "Pvp";
              break;
            case "knockOut":
              navigateTo = "KnockOut";
              break;
              case "Tournament":
              navigateTo = "Tournament";
              break;
            default:
              break;
          }

          navigation.navigate(navigateTo, navigationParams);
        }}
      >
        <Text style={[styles.dateStyle]}>
          {moment(TheGame.date).isSame(new Date(), "day")
            ? "Today"
            : moment(TheGame.date).format("MMM Do, 'YY")}
        </Text>

        <View style={styles.gameInfoContainer}>
          <Text style={styles.itemDescription}>Game - {TheGame.index}</Text>
        </View>
      </Pressable>

      <View style={styles.buttonsContainer}>
        <Pressable
          onPress={() => {
            const formattedDate = moment(TheGame.date).format("YYYY-MM-DD");
            const [hours, minutes] = (TheGame.time || "00:00")
              .split(":")
              .map(Number);
            navigation.navigate("MakeDates", {
              editing: true,
              gameData: {
                id: TheGame.id,
                date: formattedDate,
                time: { hours, minutes },
                location: TheGame.location,
                currentMainGameId: mainGameId,
              },
            });
          }}
          style={styles.editButton}
        >
          <MaterialIcons name="edit" size={20} color="white" />
        </Pressable>

        <Pressable
          onPress={() => deleteDay(TheGame.id)}
          style={styles.deleteButton}
        >
          <MaterialIcons name="delete" size={20} color="white" />
        </Pressable>
      </View>
    </View>
  );

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: "white" }}>
      <FlatList
        data={theGame}
        keyExtractor={(item, index) => index.toString()}
        renderItem={renderItem}
        ListHeaderComponent={
          <View style={styles.header}>
            <Text style={styles.headerText}>Game name: {gameName}</Text>
            {/* <Text style={styles.roomIdText}>Room Id: {roomId}</Text> */}
            <Pressable onPress={copyToClipboard}>
              <View style={styles.centerView}>
                <Text style={styles.centerText}>
                  <Text style={{ fontWeight: "bold" }}>Tap here</Text> to copy
                  Room Id for friends but they can only view Leaderboard.
                </Text>
                <Text style={styles.centerText}>
                  The owner needs to invite new members for game participation
                  via email.
                </Text>
              </View>
            </Pressable>
            <View style={styles.image}>
              {imgSrc && <Image source={imgSrc} style={styles.image} />}
            </View>
            <View style={styles.checkboxContainer}>
              <View
                style={[
                  styles.checkbox,
                  {
                    backgroundColor:
                      gameMode === "individual" ? "blue" : "white",
                  },
                ]}
              >
                <Text style={styles.checkboxText}>
                  {gameMode === "individual" ? "✓" : ""}
                </Text>
              </View>
              <Text>Individual Mode</Text>

              <View
                style={[
                  styles.checkbox,
                  { backgroundColor: gameMode === "team" ? "blue" : "white" },
                ]}
              >
                <Text style={styles.checkboxText}>
                  {gameMode === "team" ? "✓" : ""}
                </Text>
              </View>
              <Text>Team Mode</Text>

              <View
                style={[
                  styles.checkbox,
                  {
                    backgroundColor:
                      gameMode === "beerCounter" ? "blue" : "white",
                  },
                ]}
              >
                <Text style={styles.checkboxText}>
                  {gameMode === "beerCounter" ? "✓" : ""}
                </Text>
              </View>
              <Text>BeerCounter Mode</Text>


              <View
                style={[
                  styles.checkbox,
                  { backgroundColor: gameMode === "Tournament" ? "blue" : "white" },
                ]}
              >
                <Text style={styles.checkboxText}>
                  {gameMode === "Tournament" ? "✓" : ""}
                </Text>
              </View>
              <Text>Tournament</Text>





            </View>

            {isAdmin && (
              <View style={styles.buttonContainer}>
                <Pressable
                  onPress={() =>
                    navigation.navigate("MakeDates", { mainGameId })
                  }
                  style={({ pressed }) => [
                    styles.newButtons,
                    pressed && styles.pressedNewButtons,
                  ]}
                >
                  <MaterialIcons
                    name="calendar-month"
                    size={20}
                    color="white"
                  />
                  <Text style={styles.newText}>Add Dates</Text>
                </Pressable>

                {/* <Pressable
                  onPress={() => navigation.navigate("AddPlayerScreen", { mainGameId })}
                  style={({ pressed }) => [
                    styles.newButtons,
                    pressed && styles.pressedNewButtons,
                  ]}
                >
                  <MaterialIcons name="settings" size={20} color="white" />
                  <Text style={styles.newText}>Add Players Taka út</Text>
                </Pressable>   */}
                {/* 
                {( (isAdmin || currentUserEmail === 'dondanni@hotmail.com') && mainGameId !== "IVKgDlA9YCVGJN19m8hx" ) && (
                    <Pressable
                      onPress={() => navigation.navigate("AddPlayerScreen", { mainGameId })}
                      style={({ pressed }) => [
                        styles.newButtons,
                        pressed && styles.pressedNewButtons,
                      ]}
                    >
                      <MaterialIcons name="settings" size={20} color="white" />
                      <Text style={styles.newText}>Add Players</Text>
                    </Pressable>
                  )} */}

                {// Condition for all other games:
                ((mainGameId !== "IVKgDlA9YCVGJN19m8hx" &&
                  (isAdmin ||
                    currentUserEmail?.toLowerCase() ===
                      "dondanni@hotmail.com")) ||
                  // Condition for the specific game where only dondanni@hotmail.com sees the button:
                  (mainGameId === "IVKgDlA9YCVGJN19m8hx" &&
                    currentUserEmail?.toLowerCase() ===
                      "dondanni@hotmail.com")) && (
                  <Pressable
                    onPress={() =>
                      navigation.navigate("AddPlayerScreen", { mainGameId })
                    }
                    style={({ pressed }) => [
                      styles.newButtons,
                      pressed && styles.pressedNewButtons,
                    ]}
                  >
                    <MaterialIcons name="settings" size={20} color="white" />
                    <Text style={styles.newText}>Add Players</Text>
                  </Pressable>
                )}

                <Pressable
                  onPress={() =>
                    navigation.navigate("GameSettings", { mainGameId })
                  }
                  style={({ pressed }) => [
                    styles.newButtons,
                    pressed && styles.pressedNewButtons,
                  ]}
                >
                  <MaterialIcons name="star" size={20} color="white" />
                  <Text style={styles.newText}>Points</Text>
                </Pressable>

                {/* <Pressable
                  onPress={() => navigation.navigate("PlayersInfo", { mainGameId })}
                  style={({ pressed }) => [
                    styles.newButtons,
                    pressed && styles.pressedNewButtons,
                  ]}
                >
                  <MaterialIcons name="star" size={20} color="white" />
                  <Text style={styles.newText}>Players Info</Text>
                </Pressable> */}

                {/* Conditionally render "Players Info" button for specific user */}
                {currentUserEmail === "dondanni@hotmail.com" && (
                  <Pressable
                    onPress={() =>
                      navigation.navigate("Reminder", { mainGameId })
                    }
                    style={({ pressed }) => [
                      styles.newButtons,
                      pressed && styles.pressedNewButtons,
                    ]}
                  >
                    <MaterialIcons name="star" size={20} color="white" />
                    <Text style={styles.newText}>Players Info</Text>
                  </Pressable>
                )}
              </View>
            )}

            {/* Leaderboard button placed directly below the admin buttons */}
            <Pressable
              style={styles.leaderboardButton}
              onPress={() => {
                if (gameMode === "individual") {
                  navigation.navigate("PvpLeaderBoard", { mainGameId });
                } else if (gameMode === "beerCounter") {
                  navigation.navigate("BeerCountLeaderboard", { mainGameId });
                } else {
                  navigation.navigate("GameDashboard", { mainGameId });
                }
              }}
            >
              <Text style={styles.buttonText}>Leaderboard</Text>
            </Pressable>
          </View>
        }
        ListFooterComponent={
          isViewer && (
            <View style={styles.viewerMessageContainer}>
              <Text style={styles.viewerMessage}>
                Ask owner for permission to join games. You can only see
                leaderboard if you join by room Id key.
              </Text>
            </View>
          )
        }
      />
    </SafeAreaView>
  );
};

export default TheGame;

const styles = StyleSheet.create({
  header: {
    alignItems: "center",
    elevation: 4,
    padding: 20,
    justifyContent: "center",
  },
  headerText: {
    fontSize: 22,
    color: "#303030",
  },
  roomIdText: {
    fontStyle: "italic",
    textAlign: "center",
    fontSize: 15,
    color: "#303030",
    padding: 5,
  },
  buttonContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    marginTop: 30,
    marginBottom: 20,
  },
  button: {
    borderRadius: 15,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#4681f4",
    margin: 10,
    padding: 10,
  },

  leaderboardButton: {
    borderRadius: 15,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#4681f4",
    marginVertical: 5, // Minimal space above and below the button
    paddingVertical: 10,
    paddingHorizontal: 15,
    width: "100%", // Fills the width from left to right
    alignSelf: "center", // Ensures the button is centered within its container
  },

  buttonText: {
    color: "white",
    fontSize: 17,
    textAlign: "center",
  },
  listItem: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  dateStyle: {
    position: "absolute",
    left: 10,
    fontSize: 14,
  },
  itemTitle: {
    fontWeight: "bold",
    color: "#303030",
    textAlign: "center",
  },
  itemDescription: {
    color: "blue",
  },
  deleteButton: {
    position: "absolute",
    right: 10,
    padding: 1,
    borderRadius: 10,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#d9534f",
  },
  buttonsContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  checkboxContainer: {
    flexDirection: "row",
    marginTop: 10,
    alignItems: "center",
    justifyContent: "center",
  },
  checkbox: {
    width: 20,
    height: 20,
    margin: 5,
    borderColor: "blue",
    borderWidth: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fafafa",
  },
  checkboxText: {
    color: "white",
  },
  viewerMessageContainer: {
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
  },
  viewerMessage: {
    textAlign: "center",
    fontSize: 16,
    color: "#3b5998",
  },
  image: {
    width: 200,
    height: 200,
    alignSelf: "center",
    borderRadius: 100,
    borderWidth: 3,
    borderColor: "#DDD",
  },
  newButtons: {
    backgroundColor: "blue",
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 20,
    marginRight: 15,
    marginBottom: 10,
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 10,
    paddingLeft: 8,
  },
  pressedNewButtons: {
    backgroundColor: "gray",
  },
  newText: {
    marginLeft: 10,
    color: "white",
    fontSize: 15,
  },
  centerView: {
    alignItems: "center",
  },
  centerText: {
    textAlign: "center",
    fontSize: Platform.OS === "web" ? null : 11,
  },
  listItemContainer: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#FFF",
    borderRadius: 5,
    marginVertical: 5,
    marginHorizontal: 10,
    borderColor: "#91B8FF",
    borderWidth: 1,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.23,
    shadowRadius: 2.62,
    elevation: 4,
    padding: 10,
    justifyContent: "space-between",
  },
  gameInfoContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 50, // Add this line for some left spacing
  },
  itemDescription: {
    fontSize: 16,
    color: "blue",
  },
  deleteButton: {
    padding: 8,
    borderRadius: 20,
    backgroundColor: "#d9534f",
    marginLeft: "auto",
  },
  footer: {
    padding: 20,
    backgroundColor: "#fff",
  },
  editButton: {
    padding: 8,
    borderRadius: 20,
    backgroundColor: "blue",
    marginRight: 10,
  },
});
