// Almost - free winner from final round can't be chosen
import React, { useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TextInput,
  Alert,
  Platform,
  FlatList,
  TouchableOpacity,
} from "react-native";
import { Card, Button } from "react-native-paper";
import {
  collection,
  doc,
  updateDoc,
  setDoc,
  getDoc,
  deleteDoc,
  onSnapshot,
} from "firebase/firestore";
import { database } from "../componenets/firebase";

const KnockOut = ({ route, navigation }) => {
  const [mainGameId, setmainGameId] = useState(route.params.mainGameId);
  const [TheGameId, setTheGameId] = useState(route.params.TheGameId);
  const [GameDate, setGameDate] = useState(route.params.GameDate);
  const [GameIndex, setGameIndex] = useState(route.params.GameIndex);
  const [showEdit, setShowEdit] = useState(true); // This is our toggle state

  // Reference to the main game
  const mainGameRef = doc(database, "KnockOut", mainGameId);
  // Reference to the knockout game subcollection under the main game
  const knockoutGamesRef = collection(mainGameRef, "knockoutGames");
  // Reference to the rounds subcollection under the main game
  const roundsRef = collection(mainGameRef, "rounds");

  // Save main game and associated rounds to Firebase
  const saveToFirebase = async (gameData, roundsData) => {
    try {
      const gameDocRef = doc(knockoutGamesRef, TheGameId);
      // First, delete the existing document
      await deleteDoc(gameDocRef);

      // Now, set the new document
      await setDoc(gameDocRef, {
        ...gameData,
        rounds: roundsData,
      });
      //console.log("Document written with ID: ", TheGameId);
    } catch (e) {
      //console.error("Error adding document: ", e);
    }
  };

  // Get data from firebase version 9
  useEffect(() => {
    console.log("isGameRunning changed to:", isGameRunning);

    const gameRef = doc(
      database,
      "KnockOut",
      mainGameId,
      "knockoutGames",
      TheGameId
    );

    // Set up a real-time listener using onSnapshot
    const unsubscribe = onSnapshot(gameRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.data();

        if (data && data.rounds && data.rounds.length > 0) {
          setIsGameRunning(true); // Set the game as running

          // Extract matchups and log each roundData for debugging
          const fetchedRounds = data.rounds.map((roundData) => {
            //console.log("Individual round data:", roundData);
            return roundData && roundData.matchups ? roundData.matchups : [];
          });
          setRounds(fetchedRounds);

          // Extract team names, log each matchup, and set them
          const teamNamesFromDB = [];
          if (data.rounds.length > 0 && data.rounds[0].matchups) {
            const firstRoundMatchups = data.rounds[0].matchups;
            //console.log("Matchups for the first round:", firstRoundMatchups);
            for (const matchup of firstRoundMatchups) {
              teamNamesFromDB.push(matchup.team1, matchup.team2);
            }
          }
          // Debugging: log the extracted team names
          //console.log("Extracted team names:", teamNamesFromDB);
          setTeamNames(teamNamesFromDB);
        } else {
          setIsGameRunning(false);
        }
      } else {
        setIsGameRunning(false);
      }
    });
    // The return function in useEffect is used for cleanup. When the component unmounts. The listener will be detached to prevent memory leaks.
    return () => unsubscribe();
  }, [database, mainGameId, TheGameId]);

  const TeamInput = ({ teamNames, setTeamNames }) => {
    const [editingIndex, setEditingIndex] = useState(null);
    const [editedName, setEditedName] = useState("");
    const [inputError, setInputError] = useState(null);
    const handleEditPress = (index) => {
      setEditingIndex(index);
      setEditedName(teamNames[index] || "");
    };

    // Edit name
    const handleSavePress = async (index) => {
      if (editingIndex !== null) {
        // Check for duplicates
        if (
          teamNames.some(
            (name, idx) => name === editedName && idx !== editingIndex
          )
        ) {
          setInputError("Name already exists"); // Setting an error if duplicate is found
          return;
        } else {
          setInputError(""); // Resetting any error if none found
        }

        const newTeamNames = [...teamNames];
        newTeamNames[editingIndex] = editedName;
        setTeamNames(newTeamNames);
        // Now, update Firestore
        const gameRef = doc(
          database,
          "KnockOut",
          mainGameId,
          "knockoutGames",
          TheGameId
        );
        const currentRoundData = (await getDoc(gameRef)).data().rounds;

        for (let round of currentRoundData) {
          for (let matchup of round.matchups) {
            if (matchup.team1 === teamNames[editingIndex]) {
              matchup.team1 = editedName; // Update the team name
            }
            if (matchup.team2 === teamNames[editingIndex]) {
              matchup.team2 = editedName; // Update the team name
            }

            // If this team was the winner, update the winner's name
            if (matchup.winner === teamNames[editingIndex]) {
              matchup.winner = editedName;
            }
          }
        }

        // Update the rounds data in Firestore
        await updateDoc(gameRef, { rounds: currentRoundData });
        setEditingIndex(null);
        setEditedName("");
      }
    };

    const handleGenerateNewGame = () => {
      const alertMessage = "Previous game will be deleted. Are you sure?";
      const proceedWithGameGeneration = () => {
        generateKnockoutTableWithNames();
        setShowEdit(false);
      };

      if (Platform.OS === "web") {
        if (window.confirm(alertMessage)) {
          proceedWithGameGeneration();
        }
      } else {
        Alert.alert("Warning", alertMessage, [
          { text: "Cancel", style: "cancel" },
          { text: "OK", onPress: proceedWithGameGeneration },
        ]);
      }
    };
    return (
      <>
        <Button
          mode="contained"
          onPress={() => setTeamNames([...teamNames, ""])}
          style={styles.addButton} // Adjust spacing between buttons
        >
          {" "}
          Add Another Team{" "}
        </Button>

        <Button
          mode="contained"
          onPress={handleGenerateNewGame}
          style={styles.addButton} // Adjust spacing between buttons
        >
          Generate New Game
        </Button>

        {teamNames.map((name, index) => (
          <View key={index} style={styles.teamContainer}>
            <Text
              style={
                editingIndex === index
                  ? styles.teamNumber
                  : styles.teamNameStatic
              }
            >
              #{index + 1}{" "}
            </Text>

            {editingIndex === index ? (
              <>
                <TextInput
                  style={styles.input}
                  placeholder={`Team ${index + 1} Name`}
                  value={editedName}
                  onChangeText={setEditedName}
                />
                {inputError && (
                  <View style={styles.errorContainer}>
                    <Text style={styles.errorText}>{inputError}</Text>
                  </View>
                )}
                <Button
                  style={styles.saveButton}
                  mode="outlined"
                  onPress={() => handleSavePress(index)}
                >
                  Save
                </Button>
              </>
            ) : (
              <>
                <Text style={styles.teamNameStatic}>{name}</Text>
                <Button
                  style={styles.editButton}
                  mode="outlined"
                  onPress={() => handleEditPress(index)}
                >
                  Edit
                </Button>
              </>
            )}
          </View>
        ))}
      </>
    );
  };

  const [teamCount, setTeamCount] = useState(null);
  const [rounds, setRounds] = useState([]);
  const [teamsWithFreePass, setTeamsWithFreePass] = useState([]);
  const [roundsLocked, setRoundsLocked] = useState(false);
  const lockButtonLabel = roundsLocked ? "Unlock Shuffle" : "Lock Teams";
  const lockRounds = () => {
    setRoundsLocked(true);
  };
  const [teamNames, setTeamNames] = useState([]); // or some other initial value that doesn't contain null
  const [hasTableGenerated, setHasTableGenerated] = useState(false);
  //const [areTeamsLocked, setAreTeamsLocked] = useState(false);

  const shuffleArray = (array) => {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  const shuffleFirstRound = () => {
    if (roundsLocked) {
      //console.log("Rounds are locked and cannot be shuffled.");
      return; // Exit the function early if rounds are locked.
    }

    if (rounds.length) {
      const shuffledFirstRound = generateMatchups(
        shuffleArray(
          rounds[0]
            .map((matchup) => matchup.team1)
            .concat(rounds[0].map((matchup) => matchup.team2).filter(Boolean))
        )
      );
      setRounds([shuffledFirstRound]); // Reset rounds to only the shuffled first round.
    }
  };

  const toggleRoundsLock = () => {
    setRoundsLocked((prevLocked) => !prevLocked);
  };

  const getRoundName = (matchesLength, roundIndex) => {
    // If it's the last round and has only one matchup with a null team2, it's the "Champion"
    if (
      roundIndex === rounds.length - 1 &&
      matchesLength === 1 &&
      rounds[roundIndex][0].team2 === null
    ) {
      return "Champion";
    }

    // If it's a round with only one matchup but hasn't reached the "Champion" condition, then it's the "Finals"
    if (matchesLength === 1) {
      return "Finals";
    }

    // Otherwise, use your existing logic for other round names
    switch (matchesLength * 2) {
      case 16:
        return "Preliminary";
      case 8:
        return "Quarter-finals";
      case 4:
        return "Semi-finals";
      default:
        return `Round of ${matchesLength * 2}`;
    }
  };

  const generateKnockoutTableWithNames = () => {
    console.log("Generate Knockout Table triggered");

    const validTeamNames = teamNames.filter(
      (name) => name && typeof name === "string" && name.trim() !== ""
    );

    if (validTeamNames.length < 2) {
      Alert.alert("Error", "You need at least 2 teams for a match.");
    } else {
      generateKnockoutTable(shuffleArray(validTeamNames));
      setHasTableGenerated(true);
    }
  };

  const startNewGame = () => {
    setTeamCount(null);
    setRounds([]);
    setInputValue("");
    setTeamsWithFreePass([]);
  };

  const generateMatchups = (teams) => {
    const matchups = [];
    teams = teams.sort((a, b) => {
      if (teamsWithFreePass.includes(a) && !teamsWithFreePass.includes(b))
        return 1;
      if (!teamsWithFreePass.includes(a) && teamsWithFreePass.includes(b))
        return -1;
      return 0;
    });
    while (teams.length) {
      let team1 = teams.pop();
      let team2 = teams.pop();

      if (!team2 && teamsWithFreePass.includes(team1)) {
        for (let i = 0; i < teams.length; i++) {
          if (!teamsWithFreePass.includes(teams[i])) {
            team2 = teams[i];
            teams.splice(i, 1);
            break;
          }
        }
      }
      if (!team2) {
        setTeamsWithFreePass([...teamsWithFreePass, team1]);
      }

      matchups.push({
        team1: team1,
        team2: team2 || null,
        winner: null,
      });
    }
    return matchups;
  };

  const generateKnockoutTable = (initialTeams) => {
    const matchups = generateMatchups(shuffleArray(initialTeams));
    setRounds([matchups]);
  };

  const handleWinner = async (roundIndex, matchupIndex, winner) => {
    console.log("Handling winner:", roundIndex, matchupIndex, winner);
    console.log("Rendering KnockoutTable with rounds:", rounds);

    const newRounds = [...rounds];
    const currentMatchup = newRounds[roundIndex][matchupIndex];

    if (currentMatchup.team2 === null) {
      // If there's no second team, set the "Free Pass" field
      currentMatchup.freePass = "Free Pass";
    } else {
      currentMatchup.winner = winner;
    }

    // Remove all the rounds that come after the current round.
    newRounds.splice(roundIndex + 1);

    const currentRound = newRounds[roundIndex];

    if (getRoundName(currentRound.length, roundIndex) === "Finals" && winner) {
      newRounds.push([{ team1: winner, team2: null, winner: winner }]);
    } else if (
      currentRound.every((matchup) => matchup.winner || !matchup.team2)
    ) {
      const nextRoundTeams = currentRound.map(
        (matchup) => matchup.winner || matchup.team1
      );
      const nextRound = generateMatchups(nextRoundTeams);
      newRounds.push(nextRound);
    }
    setRounds(newRounds);

    // Update the rounds in Firestore
    const gameRef = doc(
      database,
      "KnockOut",
      mainGameId,
      "knockoutGames",
      TheGameId
    );
    try {
      const transformedRounds = newRounds.map((round, index) => {
        // Enforce the 'Free Pass' rule
        round.forEach((matchup) => {
          if (!matchup.team2 && !matchup.freePass) {
            matchup.freePass = "Free Pass";
          }
        });

        return {
          roundName: getRoundName(round.length, index),
          matchups: round,
        };
      });

      await setDoc(gameRef, { rounds: transformedRounds }, { merge: true }); // Using merge option so you don't accidentally overwrite other fields in the document
      //console.log("Successfully updated rounds with round names in Firestore.");
    } catch (error) {
      //console.error("Error updating rounds in Firestore: ", error);
    }
  };

  // First, declare the state variable to determine if the game is running
  const [isGameRunning, setIsGameRunning] = useState(false);

  // Then, declare the state variable for the button text
  const [buttonText, setButtonText] = useState(
    "Generate Random Knockout Table"
  );

  // Modify the onPress function of the button to handle the "Generate Random Knockout Table" logic
  const handleButtonPress = () => {
    if (!isGameRunning && showEdit) {
      generateKnockoutTableWithNames();
      setIsGameRunning(true); // Set the game as running after generating the table
      setShowEdit(false); // Switch to the knockout table view
    } else {
      setShowEdit(!showEdit); // Toggle between editing and showing the knockout table
    }
  };

  // Add a useEffect to change the buttonText depending on isGameRunning status
  useEffect(() => {
    if (isGameRunning) {
      setButtonText("Show Knockout Table");
    } else {
      setButtonText("Generate Random Knockout Table");
    }
  }, [isGameRunning]);

  const ROUND_WIDTH = 250; // This is just an example. Adjust accordingly.

  const flatListRef = useRef(null);

  useEffect(() => {
    if (flatListRef.current) {
      // Scroll to a specific offset. Adjust as needed.
      flatListRef.current.scrollToOffset({ offset: 100, animated: true });

      // OR scroll to the end of the list.
      // flatListRef.current.scrollToEnd({ animated: true });
    }
  }, []);

  const renderRound = ({ item: round, index: roundIndex }) => (
    <View style={styles.round}>
      <Text style={styles.roundName}>
        {getRoundName(round.length, roundIndex)}
      </Text>
      <FlatList
        data={round}
        keyExtractor={(matchup, matchupIndex) => "matchup-" + matchupIndex}
        renderItem={({ item: matchup, index: matchupIndex }) => (
          <View style={styles.cardLikeContainer}>
            <TouchableOpacity
              disabled={
                rounds.length - 1 === roundIndex &&
                round.length === 1 &&
                matchup.winner // Adjusted the condition here
              }
              style={[
                styles.teamContainer,
                matchup.winner === matchup.team1 &&
                  styles.selectedTeamBackground,
                matchup.winner === matchup.team2 && styles.losingTeamBackground,
                !matchup.team2 && styles.freePassWinnerBackground,
              ]}
              onPress={() =>
                handleWinner(roundIndex, matchupIndex, matchup.team1)
              }
            >
              <Text
                style={[
                  styles.team,
                  matchup.winner === matchup.team1 && styles.winningTeam,
                  matchup.winner === matchup.team2 && styles.losingTeam,
                  !matchup.team2 && styles.freePassWinnerTextColor,
                ]}
              >
                {matchup.team1}
              </Text>
            </TouchableOpacity>
            {matchup.team2 ? (
              <>
                <Text style={styles.vs}>vs</Text>
                <TouchableOpacity
                  style={[
                    styles.teamContainer,
                    matchup.winner === matchup.team2 &&
                      styles.selectedTeamBackground,
                    matchup.winner === matchup.team1 &&
                      styles.losingTeamBackground,
                  ]}
                  onPress={() =>
                    handleWinner(roundIndex, matchupIndex, matchup.team2)
                  }
                >
                  <Text
                    style={[
                      styles.team,
                      matchup.winner === matchup.team2 && styles.winningTeam,
                      matchup.winner === matchup.team1 && styles.losingTeam,
                    ]}
                  >
                    {matchup.team2}
                  </Text>
                </TouchableOpacity>
              </>
            ) : rounds.length - 1 === roundIndex && round.length === 1 ? (
              <Text style={styles.champion}>Champion!</Text>
            ) : (
              <Text style={styles.freePass}>Free Pass</Text>
            )}
          </View>
        )}
      />
    </View>
  );

  return (
    <>
    <Button
  mode="contained"
  onPress={() => setShowEdit(!showEdit)}
  style={[
    styles.addButton, // Your existing style
    isGameRunning && styles.greenButton, // Apply greenButton style when isGameRunning is true
  ]}
>
  {showEdit ? buttonText : "Game Setup"}
</Button>

      {showEdit ? (
        <ScrollView>
          <TeamInput teamNames={teamNames} setTeamNames={setTeamNames} />
        </ScrollView>
      ) : (
        <View style={styles.knockoutTable}>
          {/* Here, I'm wrapping everything related to the knockout table in a styled View */}
          <View style={styles.buttonContainer}>
            <Button
              onClick={shuffleFirstRound}
              disabled={roundsLocked}
              labelStyle={{ color: "white" }}
              style={{
                ...styles.Shufflebutton,
                ...(roundsLocked ? styles.disabledButton : styles.activeButton),
              }}
            >
              Shuffle Teams
            </Button>

            <Button
              mode="contained"
              style={[
                styles.Shufflebutton,
                roundsLocked ? styles.lockButton : null,
              ]}
              onPress={toggleRoundsLock}
            >
              {lockButtonLabel}
            </Button>

            <Button
              mode="contained"
              style={[
                styles.Shufflebutton,
                { backgroundColor: "#A8D5BA" }, // This will set the background color to pink
                // If there are other conditional styles you'd want to apply, add them here
              ]}
              onPress={() => navigation.navigate("KnockOutResults")}
            >
              <Text style={styles.buttonText}>Instructions</Text>
            </Button>

          </View>

          <FlatList
            ref={flatListRef}
            data={rounds}
            horizontal={true}
            showsHorizontalScrollIndicator={true}
            keyExtractor={(round, roundIndex) => "round-" + roundIndex}
            renderItem={renderRound}
          />
        </View>
      )}
    </>
  );
};

export default KnockOut;

const styles = StyleSheet.create({
  knockoutTable: {
    flex: 1,
    backgroundColor: "#fff",
  },
  round: {
    padding: 10,
    margin: 5,
    marginRight: 50, // Adjust this value for horizontal spacing
    // borderWidth: 1,
    borderColor: "gray",
    alignItems: "center", // Added this line
  },
  roundName: {
    // Create a new style for the round name
    fontWeight: "bold",
    marginBottom: 10,
    textAlign: "center",
  },
  cardLikeContainer: {
    flexDirection: "column", // Changed to column
    justifyContent: "center",
    alignItems: "center",
    margin: 5,
    padding: 10,
    borderWidth: 1,
    borderColor: "gray",
    marginBottom: 30, // Adjust this value for vertical spacing
    backgroundColor: "#f7f3f9", // Card background
    borderRadius: 12, // Increased border-radius for a rounded appearance
    width: "90%", // Adjust this value for width. You can increase or decrease as per your requirement.
  },

  // // // cardLikeContainer: {
  // // //   flexDirection: "column", // Changed to column
  // // //   justifyContent: "center",
  // // //   alignItems: "center",
  // // //   margin: 5,
  // // //   padding: 10,
  // // //   paddingHorizontal: 20,  // Increase this value for more width

  // // //   borderWidth: 1,
  // // //   borderColor: "gray",
  // // //   marginBottom: 30, // Adjust this value for vertical spacing
  // // //   backgroundColor: "#f7f3f9", // Card background
  // // //   borderRadius: 25, // Increased border-radius for a more rounded appearance
  // // //   shadowColor: '#000',
  // // //   shadowOffset: { width: 0, height: 2 },
  // // //   shadowOpacity: 0.23, // Adjusted for a subtle shadow
  // // //   shadowRadius: 2.62,
  // // //   elevation: 4, // Adjusted elevation for Android
  // // // },

  teamContainer: {
    padding: 10,
  },
  team: {
    fontSize: 16,
  },
  selectedTeamBackground: {
    backgroundColor: "#2196f3",
  },
  winningTeam: {
    fontWeight: "bold",
    color: "white",
    // backgroundColor: "red",
    paddingHorizontal: 5, // Some padding to give space around the text
    borderRadius: 5, // To give a rounded edge to the background
  },
  vs: {
    marginVertical: 10, // Adjusted for vertical spacing
  },
  champion: {
    //fontWeight: 'bold',
    color: "green",
    fontSize: 18,
  },
  freePass: {
    fontStyle: "italic",
    color: "orange",
    fontSize: 16,
  },
  freePassWinnerTextColor: {
    color: "white",
    fontWeight: "bold",
  },
  freePassWinnerBackground: {
    backgroundColor: "#2196f3",
    color: "white",
  },
  losingTeamBackground: {
    //backgroundColor: "grey",
  },
  losingTeam: {
    color: "#A3A3A3",
  },
  input: {
    flex: 1,
    marginVertical: 10, // Even spacing on top and bottom
    marginHorizontal: 20, // Spacing from the sides
    padding: 10,
    height: 40, // Fixed height
    borderWidth: 1,
    borderColor: "#ccc",
    borderRadius: 5,
    shadowColor: "#000", // Shadow for depth
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.2,
    shadowRadius: 1.41,
    elevation: 2, // Elevation for Android
    textAlign: "center", // Center the text
  },
  teamContainer: {
    flexDirection: "row", // to layout team name and button in a row
    justifyContent: "space-between", // align team name to left and button to right
    alignItems: "center", // vertically center-align items
    padding: 10, // space around the team name and button
    marginVertical: 5, // space between consecutive team name entries
    borderWidth: 1, // thin border around each entry
    borderColor: "#e0e0e0", // light gray border color
    borderRadius: 5, // rounded corners
    backgroundColor: "#f5f5f5", // light background to distinguish the entry
  },
  errorContainer: {
    flex: 1,
    justifyContent: "flex-start",
  },
  errorText: {
    color: "red",
    alignSelf: "stretch", // Make the error text span the width
    marginLeft: 10, // A bit of space to align with the TextInput
    marginTop: 5, // A bit of space between the TextInput and the error
  },
  addButton: {
    //flex: 1,
    //marginLeft: 5,
    //margin: 25,
    // borderWidth : 1,
    // borderColor: 'red',
    // color: 'green',
    backgroundColor: "#6750a4", // Change this color for the "Add Another Team" button
  },
  buttonContainer: {
    flexDirection: "row",
    // justifyContent: "space-between",
    padding: 10,
  },
  Shufflebutton: {
    flex: 1, // makes sure buttons take up an equal amount of space
    margin: 5,
    //backgroundColor:  "red" /* Soft green */
    //color: "#FFFFFF", // White text color
    //backgroundColor:  "#8E4585" /* Soft green */
  },
  activeButton: {
    backgroundColor: "#2196F3", // Blue color
    color: "#FFFFFF", // White text color
    padding: 5, // Adjust padding as necessary to maintain size
    border: "none",
    borderRadius: 25,
    cursor: "pointer",
  },
  disabledButton: {
    backgroundColor: "#9E9E9E", // Gray color
    color: "#FFFFFF", // White text color
    padding: 5, // Adjust padding as necessary to maintain size
    border: "none",
    borderRadius: 25,
    cursor: "not-allowed", // This changes the cursor on hover to indicate it's not clickable
  },
  lockButton: {
    backgroundColor: "#9E9E9E",
  },
  greenButton: {
    backgroundColor: "green", // Set the background color to green
  },
});
